import './MovementScss.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { OrderByNameCashierEnum, PermissionsEnum } from 'erva-doce-common';
import FieldDate from '../../components/FieldDate';
import ScreenHeader from '../../components/logged/ScreenHeader';
import FieldSelect from '../../components/FieldSelect';
import Section from '../../components/Section';
import Table from '../../components/Table';
import PaymentMethodTableComponent from './PaymentMethodTable';
import { formatValue } from '../../util/utilCurrency';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { getCashClosingPage } from './CashClosing';
import { getDateToDayForInputHtml } from '../../util/utilDate';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as MovementService from '../../services/MovementService';
import * as CashierService from '../../services/CashiersService';

export function getPanelPage() {
    return '/vendas/painel';
}

export default function PanelPage() {
    const title = 'Painel';
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const navigate = useNavigate();
    const { user, addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const [data, setDate] = useState(getDateToDayForInputHtml());
    const [caixa, setCaixa] = useState([]);
    const [shoppingInterval, setShoppingInterval] = useState([]);
    const [machineValues, setMachineValues] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState({
        name: 'Nenhum caixa foi selecionado ainda',
        id: 0,
        value: '',
    });

    const formatedDate = () => {
        if (!data) return 'Informações das vendas por hora';
        const [aaaa, mm, dd] = data.split('-');
        return `${dd}/${mm}/${aaaa} Informações das vendas por hora`;
    };

    const fetchCaixas = async () => {
        try {
            const result = await CashierService.getCashiers(
                0,
                null,
                OrderByNameCashierEnum.NAME_DESC
            );
            const cashierList = result?.records?.map((record) => ({
                value: record.name,
                collaborator: {
                    name: record.collaborator.name,
                    id: record.collaborator.id,
                    uuid: record.collaborator.uuid,
                },
            }));
            setCaixa(cashierList);
        } catch (error) {
            console.error('Erro ao buscar caixas:', error);
        }
    };

    const fetchCashMovements = async () => {
        try {
            const fetch = async ({ id }) => {
                const result = await MovementService.getCashMovements({
                    date: data,
                    userId: id,
                });
                setShoppingInterval(result);
            };

            if (selectedCollaborator?.id !== 0) {
                await fetch({ id: selectedCollaborator?.id });
                return;
            }

            if (user?.cashier?.collaborator && selectedCollaborator?.id !== 0) {
                await fetch({ id: user?.cashier?.collaborator });
                return;
            }

            setShoppingInterval([]);
        } catch (error) {
            console.error('Erro ao buscar movimentos de caixa:', error);
        }
    };

    const fetchMachineValues = async () => {
        try {
            const fetch = async ({ id }) => {
                const result = await MovementService.getMachineValues({
                    date: data,
                    userId: id,
                });
                setMachineValues(result);
            };

            if (selectedCollaborator?.id !== 0) {
                await fetch({ id: selectedCollaborator?.id });
                return;
            }

            if (user?.cashier?.collaborator && selectedCollaborator?.id !== 0) {
                await fetch({ id: user?.cashier?.collaborator });
                return;
            }

            setMachineValues([]);
        } catch (error) {
            console.error('Erro ao buscar valores da máquina:', error);
        }
    };

    const handleCollaboratorChange = ({ value }) => {
        const collaborator = caixa?.find((user) => user?.value === value);
        if (collaborator) {
            setSelectedCollaborator({
                ...collaborator.collaborator,
                value: collaborator?.value,
            });
        } else {
            setSelectedCollaborator({
                name: 'Nenhum caixa foi selecionado ainda',
                id: 0,
                value: '',
            });
        }
    };

    useEffect(() => {
        fetchCaixas();
    }, []);

    useEffect(() => {
        const value = user?.cashier?.name;
        if (value) {
            handleCollaboratorChange({ value });
        }
    }, [caixa]);

    useEffect(() => {
        if (data || selectedCollaborator) {
            fetchMachineValues();
            fetchCashMovements();
        }
    }, [data, selectedCollaborator]);

    useEffect(() => {
        const f3Hotkey = addHotkey('F3', () =>
            buttonDeleteRef.current?.click()
        );
        const f6Hotkey = addHotkey('F6', () => buttonSaveRef.current?.click());

        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, [addHotkey, removeHotkey]);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Caixa', route: '/' },
                    { name: title, route: getPanelPage() },
                ]}
            />

            <div className={'gd'}>
                <div className={'gd-col gd-col-6'} />
                <div className={'gd-col gd-col-2'}>
                    <FieldDate
                        onChange={({ target }) => {
                            setDate(target.value);
                        }}
                        value={data}
                    />
                </div>

                {user?.roles?.includes(PermissionsEnum.HANDLE_SALES) ? (
                    <>
                        <div className={'gd-col gd-col-2'}>
                            <FieldSelect
                                options={caixa}
                                placeholder={'Selecione um caixa'}
                                disableDefaultOption={false}
                                onChange={(e) => {
                                    let type = e.target.value;
                                    if (e.target.value === '-1') {
                                        type =
                                            'Nenhum caixa foi selecionado ainda';
                                    }
                                    handleCollaboratorChange({ value: type });
                                }}
                                value={selectedCollaborator?.value}
                            />
                        </div>
                        <div
                            className={'gd-col gd-col-2 user-collaborator-box'}
                        >
                            <div className={'user-collaborator-box'}>
                                <p>{selectedCollaborator.name}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={'gd-col gd-col-2 user-collaborator-box'}
                        >
                            <div className={'user-collaborator-box'}>
                                <p>{user?.cashier?.name}</p>
                            </div>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <div className={'user-collaborator-box'}>
                                <p>{user?.name}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Section title={formatedDate()}>
                <Table
                    columns={[
                        { name: 'Hora inicial', align: 'center' },
                        { name: 'Hora final', align: 'center' },
                        { name: 'Qtd. Vendas', align: 'center' },
                        { name: 'Total vendido', align: 'center' },
                    ]}
                >
                    {shoppingInterval.map((sale, index) => (
                        <tr key={index}>
                            <td className={'center'}>{sale.startTime}</td>
                            <td className={'center'}>{sale.endTime}</td>
                            <td className={'center'}>{sale.quantity}</td>
                            <td className={'center'}>
                                {formatValue({ value: sale.totalSales })}
                            </td>
                        </tr>
                    ))}
                </Table>
            </Section>

            <PaymentMethodTableComponent data={machineValues} />

            <div className={'controls row mt-42'}>
                <div className={'col-10'} />
                <div className={'col-2'}>
                    <Button
                        className={'w-100'}
                        ref={buttonSaveRef}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                        onClick={() =>
                            navigate(
                                getCashClosingPage(selectedCollaborator.id)
                            )
                        }
                    >
                        {'Fechar caixa [F6]'}
                    </Button>
                </div>
            </div>
        </div>
    );
}
