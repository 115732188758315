const blur = (name) => {
    if (!name) return;

    const last11Chars = name.substring(name.length - 11);
    const isMEI = !isNaN(last11Chars);

    if (isMEI) {
        return name.replace(last11Chars, '*********-**');
    }

    const splittedName = name.split(' ');

    if (splittedName.length <= 1 ) return '*****';

    const firstName = splittedName[0];
    const lastName = splittedName[splittedName.length - 1] || '';
    const last3Chars = lastName.substring(lastName.length - 3);

    return `${firstName} *****${last3Chars}`;
};

const anonymize = () => {
    return 'Anonimizado pelo Sistema';
};

const name = {
    blur,
    anonymize,
};

export default name;
