import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useContext, useState } from 'react';
import Pills from '../../components/Pills';
import FieldStore from '../../components/FieldStore';
import { EnvironmentContext } from '../../contexts/EnviromentContext';


function ExchangesFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const { user } = useContext(EnvironmentContext);
    const [stores, setStores] = useState(filter?.stores ?? []);

    function confirm() {
        onConfirm({
            stores,
        });
    }

    function cancel() {
        onCancel();
        setStores(filter.stores ?? []);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar devoluções'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    {
                        user.isAdmin && (
                            <FieldStore
                                multipleSelection={true}
                                onAddItem={(record) => {
                                    setStores((store) => {
                                        return [
                                            ...store,
                                            record
                                        ];
                                    });
                                }}
                                ignoreItems={stores.map(record => record.id)}
                            >
                                <Pills
                                    pills={stores}
                                    onRemoveItem={(record) => {
                                        setStores((store) => {
                                            return store.filter(item => item.id !== record.id);
                                        });
                                    }}
                                />
                            </FieldStore>
                        )
                    }
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default ExchangesFilterModal;
