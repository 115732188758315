import { DateTime } from 'luxon';

export function getDateToDayForInputHtml() {
    const date = new Date();
    const [dd, mm, yyyy] = date.toLocaleDateString('pt-br').split('/');
    return `${yyyy}-${mm}-${dd}`;
}

export function getCurrentDate() {
    const dateWithoutTime = DateTime.local().startOf('day');
    const timestamp = dateWithoutTime.toMillis();
    return timestamp;
}

export function formatedDateForFetchAPi({ date }) {
    const dt = DateTime.fromISO(date);
    const startOfDay = dt.startOf('day');     
    const endOfDay = dt.endOf('day');     
    return { startOfDay, endOfDay };
}