import { useState } from 'react';
import Table from '../../components/Table';
import FieldNumber from '../../components/FieldNumber';
import { formatValue } from '../../util/utilCurrency';
import Section from '../../components/Section';
import ButtonRemove from '../../components/ButtonRemove';

const TOTALS_TABLE = [
    {
        coin: 'fiveCents',
        total: 0,
        pct: 20,
        value: 0.05,
        pacote: '',
        avulso: '',
    },
    { coin: 'tenCents', total: 0, pct: 10, value: 0.1, pacote: '', avulso: '' },
    {
        coin: 'twentyFiveCents',
        total: 0,
        pct: 5,
        value: 0.25,
        pacote: '',
        avulso: '',
    },
    {
        coin: 'fiftyCents',
        total: 0,
        pct: 2,
        value: 0.5,
        pacote: '',
        avulso: '',
    },
    { coin: 'oneReal', total: 0, pct: 10, value: 1, pacote: '', avulso: '' },
    { coin: 'fiveReal', total: 0, pct: 20, value: 5, pacote: '', avulso: '' },
    { coin: 'tenReal', total: 0, pct: 10, value: 10, pacote: '', avulso: '' },
    { coin: 'twentyReal', total: 0, pct: 5, value: 20, pacote: '', avulso: '' },
    { coin: 'fiftyReal', total: 0, pct: 2, value: 50, pacote: '', avulso: '' },
    {
        coin: 'hundredReal',
        total: 0,
        pct: 1,
        value: 100,
        pacote: '',
        avulso: '',
    },
];

export default function CalculateCash() {
    const [totalTable, setTotalTable] = useState(TOTALS_TABLE);
    
    const handleInputChange = ({ index, field, value }) => {
        const newValue = Number(value);
        const updatedTotalTable = [...totalTable];
        const item = updatedTotalTable[index];

        item[field] = newValue;

        item.total =
            item.pacote * item.pct * item.value + item.avulso * item.value;
        setTotalTable(updatedTotalTable);
    };

    const returnTotalTable = () => {
        const result = totalTable?.reduce(
            (acc, item) => (acc += item.total),
            0
        );
        return formatValue({ value: result });
    };

    const deleteRowTotal = ({ index }) => {
        const updatedTotalTable = [...totalTable];
        const item = updatedTotalTable[index];
        item.avulso = 0;
        item.pacote = 0;
        item.total = 0;
        setTotalTable(updatedTotalTable);
    };

    return (
        <Section title={'Dinheiro'}>
            <div className={'money-container'}>
                <div className={'money-container-title'}>
                    <div>
                        <p>{'Item 1'}</p>
                    </div>
                    <div>
                        <p>
                            <i>
                                <strong>{'Total em dinheiro R$ 400,00'}</strong>
                            </i>
                        </p>
                    </div>
                </div>
                <div className={'money-container-body'}>
                    <Table
                        columns={[
                            { name: 'Qtd. Pacote', align: 'center' },
                            { name: 'Valor do pacote', align: 'center' },
                            { name: 'Qtd. Avulso', align: 'center' },
                            {
                                name: `Total ${returnTotalTable()}`,
                                align: 'center',
                            },
                            { name: '', align: 'center' },
                        ]}
                    >
                        {totalTable?.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td className={'field-center'}>
                                        <FieldNumber
                                            minValue={0}
                                            thousandsSeparator={false}
                                            onChange={({ target }) => {
                                                handleInputChange({
                                                    field: 'pacote',
                                                    index: index,
                                                    value: target.value,
                                                });
                                            }}
                                            value={row?.pacote}
                                        />
                                    </td>
                                    <td className={'center'}>
                                        <div>
                                            <p>
                                                {formatValue({
                                                    value: row.value,
                                                })}
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'field-center'}>
                                        <FieldNumber
                                            style={{ width: '160px' }}
                                            minValue={0}
                                            thousandsSeparator={false}
                                            onChange={({ target }) => {
                                                handleInputChange({
                                                    field: 'avulso',
                                                    index: index,
                                                    value: target.value,
                                                });
                                            }}
                                            value={row?.avulso}
                                        />
                                    </td>
                                    <td className={'center'}>
                                        <div
                                            className={
                                                'd-flex justify-content-between'
                                            }
                                        >
                                            <p>
                                                {formatValue({
                                                    value: row.total,
                                                })}
                                            </p>
                                            <ButtonRemove
                                                onClick={() =>
                                                    deleteRowTotal({
                                                        index,
                                                    })
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td className={'center'}></td>
                                </tr>
                            );
                        })}
                    </Table>
                </div>
            </div>
        </Section>
    );
}
