const blur = (email) => {
    if (!email) return '*****';

    const [host, domain] = email.split('@');
    const first3Chars = host.substring(0, 3);

    return `${first3Chars}***@${domain}`;
};

const anonymize = () => {
    const randomNumber = Math.ceil(Math.random() * 9999 + 1);

    return `anonimo-${randomNumber}@example.org`;
};

const email = {
    blur,
    anonymize,
};

export default email;
