import { forwardRef, useCallback, useEffect, useRef } from 'react';
import nextId from 'react-id-generator';
import Field from './Field';

export const FieldTextStyle = {
    FIELD_NORMAL: 'field-normal', // css class correspondent
    FIELD_LOGIN: 'field-login', // css class correspondent
};

export const FieldTextLabelStyle = {
    LABEL_ABOVE: 'label-above', // css class correspondent
    LABEL_INLINE: 'label-inline', // css class correspondent
};

function FieldText({
    disabled,
    children,
    required,
    icon,
    icon2x,
    className,
    type,
    value,
    onChange,
    onKeyUp,
    onKeyDown,
    onFocus,
    onBlur,
    placeholder,
    autoComplete,
    readOnly,
    maxLength,
    title,
    inputContainerChildren,
    radius,
    textAlign,
    height,
    pointer,
    onChangeDebounce,
    enableDebounce = true,
    debounceTime = 700,
    ...props
}, ref) {
    const {
        labelText,
        labelStyle,
        validationError,
    } = props;

    const id = props.id ?? nextId('field-text');
    const idError = `${id}-error`;
    const fieldRef = useRef();
    const firstUpdate = useRef(true);
    let inputStyle = {};
    if (icon) {
        if (icon2x) {
            inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            inputStyle.backgroundImage = `url(${icon})`;
        }
    }

    if (radius) {
        inputStyle.borderRadius = radius;
    }

    if (textAlign) {
        inputStyle.textAlign = textAlign;
    }

    if (height) {
        inputStyle.height = height;
    }

    if (pointer) {
        inputStyle.cursor = 'pointer';
    }

    className = className ?? '';
    if (icon && className.indexOf('has-icon') === -1) {
        className += ' has-icon';
    }

    useEffect(() => {
        if (labelStyle === FieldTextLabelStyle.LABEL_INLINE && value) {
            fieldRef.current.label.style.visibility = 'hidden';
        }
    }, [fieldRef, value, labelStyle]);

    const executeOnChangeDebounce = useCallback(() => {
        if (enableDebounce && !!onChangeDebounce) {
            onChangeDebounce();
        }
    }, [enableDebounce, onChangeDebounce]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const timeout = setTimeout(
            () => executeOnChangeDebounce(),
            debounceTime
        );
        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            className={className}
        >
            <div className={'input-container'}>
                <input
                    ref={ref}
                    id={id}
                    type={type ?? 'text'}
                    required={!!required}
                    aria-required={!!required}
                    aria-invalid={!!validationError}
                    aria-errormessage={idError}
                    style={inputStyle}
                    title={title ?? labelText}
                    value={value}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    onChange={(e) => {
                        if (labelStyle === FieldTextLabelStyle.LABEL_INLINE
                            && fieldRef.current?.label
                            && e.target.value !== '') {
                            fieldRef.current.label.style.visibility = 'hidden';
                        } else {
                            fieldRef.current?.label?.style.removeProperty('visibility');
                        }
                        if (onChange) onChange(e);
                    }}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={(e) => {
                        if (
                            labelStyle === FieldTextLabelStyle.LABEL_INLINE &&
                            fieldRef.current?.label &&
                            e.target.value !== ''
                        ) {
                            fieldRef.current.label.style.visibility = 'hidden';
                        } else {
                            fieldRef.current?.label?.style.removeProperty('visibility');
                        }
                        if (onBlur) onBlur(e);
                    }}
                    disabled={disabled}
                />
                {inputContainerChildren}
            </div>
        </Field>
    );
}

export default forwardRef(FieldText);
