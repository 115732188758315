export enum ValidationErrorCampaignEnum {
  CAMPAIGN_NAME_ALREADY_IN_USE = "CAMPAIGN_NAME_ALREADY_IN_USE",
  ALREADY_EXISTS_CAMPAIGN_FOR_STORE = "ALREADY_EXISTS_CAMPAIGN_FOR_STORE",
}

export function ValidationErrorCampaignText(
  validationError: ValidationErrorCampaignEnum
): string {
  switch (validationError) {
    case ValidationErrorCampaignEnum.CAMPAIGN_NAME_ALREADY_IN_USE:
      return "Já existe uma campanha com esse nome";
    case ValidationErrorCampaignEnum.ALREADY_EXISTS_CAMPAIGN_FOR_STORE:
      return "Já existe uma campanha para a loja selecionada";
    default:
      throw new Error(`Not implemented for ${validationError}`);
  }
}
