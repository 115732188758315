const blur = (phone) => {
    if (!phone) return '**********';

    const replaced = phone.replace(/\D/g, '');
    const first4Chars = replaced.substring(0, 4);
    const last2Chars = replaced.substring(replaced.length - 2);

    return `${first4Chars}*****${last2Chars}`;
};

const anonymize = () => {
    return '9999999999';
};

const phone = {
    blur,
    anonymize,
};

export default phone;
