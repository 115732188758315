import {
    PermissionsEnum,
    ValidationErrorCfopText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldInteger from '../../components/FieldInteger';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as CfopService from '../../services/CfopService';
import { getCfopRoute } from './Cfop';

export default function CfopForm() {
    const emptyFormData = {
        name: '',
        code: ''
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingCfop, setLoadingCfop] = useState(!!uuid);
    const [cfopName, setCfopName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_FISCAL);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_FISCAL);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchCfop() {
        try {
            setLoadingCfop(true);
            const cfop = await CfopService.getCfop(uuid);
            setCfopName(cfop.name);
            setFormData({
                ...cfop
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'CFOP não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch cfop', e, null, title);
            }
        } finally {
            setLoadingCfop(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchCfop();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do CFOP' }));
        }

        if (!formData.code?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, code: 'Digite o código do CFOP' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            if (canRemove) {
                f3Hotkey = addHotkey('F3', () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            f6Hotkey = addHotkey('F6', () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    function back() {
        navigate(getCfopRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim(),
                code: formData.code.trim(),
            };

            let message;
            if (!uuid) { // new collaborator
                await CfopService.addCfop(body);
                // I18N
                message = 'CFOP salvo com sucesso!';
            } else {
                // I18N
                await CfopService.editCfop(uuid, body);
                message = 'CFOP editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit cfop', e, null, title, ValidationErrorCfopText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteCfop() {
        // I18N
        let title = 'Excluir CFOP';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await CfopService.deleteCfop(uuid);
                // I18N
                const message = 'CFOP excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete cfop', e, null, title, ValidationErrorCfopText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o CFOP?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar CFOP';
            if (windowTitle && cfopName) title += ` - ${cfopName}`;
            return title;
        } else {
            return 'Novo CFOP';
        }
    }

    const renderForm = () => {
        if (loadingCfop) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldInteger
                                    // I18N
                                    label={'Código'}
                                    // I18N
                                    placeholder={'CFOP'}
                                    onChange={({ target }) => updateFormData({ code: target.value })}
                                    value={formData?.code}
                                    thousandsSeparator={false}
                                    validationError={formError?.code}
                                    maxLength={4}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                    loading={deleteLoading}
                                    onClick={deleteCfop}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'CFOP', route: getCfopRoute() },
                        { name: uuid ? (loadingCfop ? '...' : cfopName) : title },
                    ]}
                    backRoute={getCfopRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getCfopFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/fiscal/cfop/${uuid}`;
    } else {
        return '/fiscal/cfop/novo';
    }
}
