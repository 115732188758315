import { useNavigate, useParams } from 'react-router-dom';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getOrderListRoute } from '../../order-list/OrderList';
import { getOrderDetailRoute } from '../order-detail/OrderDetail';
import OrderDetailHeaderTable from '../order-detail/OrderDetailHeaderTable';
import { useContext, useEffect, useState } from 'react';
import * as OrdersService from '../../../services/OrdersService';
import * as BillingsServices from '../../../services/BillingsServices';
import * as DocumentsServices from '../../../services/DocumentsServices';
import FieldTextSearch from '../../../components/FieldTextSearch';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import OrderBillingTable from './OrderBillingTable';
import Pagination from '../../../components/Pagination';
import * as Tabs from '@radix-ui/react-tabs';
import FieldCheckbox from '../../../components/FieldCheckbox';
import FieldNumber from '../../../components/FieldNumber';
import './OrderBilling.scss';
import OrderBillingDocumentsTable from './OrderBillingDocumentsTable';
import OrderBillingBillsTable from './OrderBillingBillsTable';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../../components/modal/InfoModal';
import { useSearchParams } from 'react-router-dom';
import ImportOrderDocumentModal from '../order-detail/ImportOrderDocumentModal';
import { union } from 'lodash';
import { formatValue } from '../../../util/formatValue';
import { BillingTypeEnum, OrderBillingFilterTabEnum, OrderBillingFilterTabText, OrderStatusEnum } from 'erva-doce-common';
import { extractCurrencyNumber, formatCurrency } from '../../../components/FieldCurrency';

const filters = {
    order: null,
    search: null,
    page: 0,
    product: null,
    orderId: null,
    status: OrderBillingFilterTabEnum.ALL,
    rateioFrete: 0,
};

const documentsFilters = {
    order: null,
    page: 0,
    documents: [],
};

const VIRTUAL_TOTALS = {
    total: 0,
    icms: 0,
    ipi: 0,
    frete: 0,
    costFinal: 0,
    totalFinal: 0,
};

export default function OrderBilling() {
    const { orderId, billingId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { setLoading, setInfoModal, setConfirmModal, backendConnectionError } = useContext(EnvironmentContext);
    const navigate = useNavigate();

    const isNewBilling = billingId === 'novo' && isNaN(billingId);
    const title = 'Faturamento';

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [showImportOrderDocumentModal, setShowImportOrderDocumentModal] = useState(false);
    const [order, setOrder] = useState({});
    const [billing, setBilling] = useState({});
    const [filter, setFilter] = useState(filters);
    const [documentsFilter, setDocumentsFilter] = useState({ ...documentsFilters, documents: [] });
    const [data, setData] = useState({});
    const [search, setSearch] = useState('');
    const [customProducts, setCustomProducts] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [documentBillings, setDocumentBillings] = useState([]);
    const [canEdit, setCanEdit] = useState({});
    const [virtualTotals, setVirtualTotals] = useState(VIRTUAL_TOTALS);
    const [virtualRecords, setVirtualRecords] = useState([]);
    const [formError, setFormError] = useState({});

    const handleVirtualTotals = () => {
        if (data.totals && customProducts.length) {
            const minusICMS = data.records.filter(x => customProducts.find(y => x.id === y.id)).reduce((a, b) => a + b.icms, 0);
            const sumICMS = customProducts.filter(x => data.records.find(y => x.id === y.id)).reduce((a, b) => a + b.icms, 0);

            const minusIPI = data.records.filter(x => customProducts.find(y => x.id === y.id)).reduce((a, b) => a + b.ipi, 0);
            const sumIPI = customProducts.filter(x => data.records.find(y => x.id === y.id)).reduce((a, b) => a + b.ipi, 0);

            const minusFrete = data.records.filter(x => customProducts.find(y => x.id === y.id)).reduce((a, b) => a + b.frete, 0);
            const sumFrete = customProducts.filter(x => data.records.find(y => x.id === y.id)).reduce((a, b) => a + b.frete, 0);

            const minusCostFinal = data.records.filter(x => customProducts.find(y => x.id === y.id)).reduce((a, b) => a + b.costFinal, 0);
            const sumCostFinal = customProducts.filter(x => data.records.find(y => x.id === y.id)).reduce((a, b) => a + b.costFinal, 0);

            const minusTotalFinal = data.records.filter(x => customProducts.find(y => x.id === y.id)).reduce((a, b) => a + b.totalFinal, 0);
            const sumTotalFinal = customProducts.filter(x => data.records.find(y => x.id === y.id)).reduce((a, b) => a + b.totalFinal, 0);

            setVirtualTotals(state => ({
                ...state,
                icms: data.totals.icms - minusICMS + sumICMS,
                ipi: data.totals.ipi - minusIPI + sumIPI,
                frete: data.totals.frete - minusFrete + sumFrete,
                costFinal: data.totals.costFinal - minusCostFinal + sumCostFinal,
                totalFinal: data.totals.totalFinal - minusTotalFinal + sumTotalFinal,
            }));
        }
    };

    const handleVirtualTotalsByRecord = (oldRecord, newRecord) => {
        if (oldRecord && newRecord) {
            setVirtualTotals(state => {
                return {
                    ...state,
                    icms: state.icms - oldRecord.icms + newRecord.icms,
                    ipi: data.totals.ipi - oldRecord.ipi + newRecord.ipi,
                    frete: data.totals.frete - oldRecord.frete + newRecord.frete,
                    costFinal: data.totals.costFinal - oldRecord.costFinal + newRecord.costFinal,
                    totalFinal: data.totals.totalFinal - oldRecord.totalFinal + newRecord.totalFinal,
                };
            });
        }
    };

    const calcItemValues = (item) => {
        const newItem = { ...item };
        const countFinal = newItem.countNoDocument + newItem.countXML;
        let count = countFinal;

        if (countFinal === 0) {
            count = item.count;
        }

        const sumTax = (newItem.icms + newItem.ipi + newItem.frete) / count;
        const costFinal = item.countCanceled < item.count ? (isNaN(sumTax) || sumTax === Infinity ? 0 : sumTax) + newItem.cost : 0;
        const total = item.countCanceled < item.count ? newItem.count * newItem.cost : 0;
        const totalFinal = countFinal * costFinal;

        newItem.total = total;
        newItem.totalFinal = totalFinal;
        newItem.costFinal = costFinal;
        newItem.countFinal = (isNaN(countFinal) ? 0 : countFinal);

        return newItem;
    };

    const updateCustomProducts = (id, target) => {
        const product = virtualRecords.find(x => x.id === id);
        const item = calcItemValues({ ...product, ...target });
        let newCustomProducts = [...customProducts];
        const alreadyExistsIndex = customProducts.findIndex(x => x.id === product.id);
        const newItem = {
            id: item.id,
            icms: item.icms,
            ipi: item.ipi,
            frete: item.frete,
            costFinal: item.costFinal,
            cost: item.cost,
            totalFinal: item.totalFinal,
            total: item.total,
            countBilled: item.countBilled,
            countXML: item.countXML,
            count: item.count,
            countNoDocument: item.countNoDocument,
        };

        if (alreadyExistsIndex < 0) {
            handleVirtualTotalsByRecord(product, newItem);
            newCustomProducts.push(newItem);
        } else {
            handleVirtualTotalsByRecord(newCustomProducts[alreadyExistsIndex], newItem);
            newCustomProducts[alreadyExistsIndex] = newItem;
        }

        setCustomProducts(newCustomProducts);
    };

    const updateVirtualItems = () => {
        if (data.records) {
            const newItems = data.records.map(item => {
                const customItem = customProducts.find(custom => custom.id === item.id);

                if (canEdit.rateioFrete && customItem) {
                    customItem.frete = item.frete;
                }

                const newItem = calcItemValues({ ...item, ...customItem });

                return newItem;
            });

            setVirtualRecords(newItems);
        }
    };

    const handleFilterTabChange = (e) => {
        setFilter(state => ({
            ...state,
            status: e,
        }));
    };

    const cancelBilling = async () => {
        const goBack = () => {
            navigate(getOrderDetailRoute(orderId));
        };

        if (isNewBilling) {
            goBack();
        } else {
            const proceed = async () => {
                try {
                    setIsLoading(true);

                    await BillingsServices.deleteBilling(billingId);

                    setInfoModal({
                        title: 'Faturamento',
                        message: 'Faturamento cancelado com sucesso',
                        onClose: goBack,
                        show: true,
                    });

                } catch(err) {
                    console.log(err);
                    backendConnectionError('Fail to delete Billing', err, null, title);
                } finally {
                    setIsLoading(false);
                }
            };

            setConfirmModal({
                title: 'Faturamento',
                message: 'Você tem certeza de que deseja cancelar o faturamento do pedido?',
                onConfirm: proceed,
                show: true,
            });
        }
    };

    const addDocumentBilling = () => {
        const documentBilling = {
            id: null,
            documentId: null,
            billingDate: '',
            number: '',
            type: BillingTypeEnum.NO_BILLING,
            total: 0,
        };

        setDocumentBillings((state) => [
            ...state,
            documentBilling,
        ]);
    };

    const deleteDocumentBilling = (index) => {
        const newDocumentBillings = [...documentBillings];

        newDocumentBillings.splice(index, 1);

        setDocumentBillings(newDocumentBillings);
    };

    const updateDocumentBilling = (index, target) => {
        const newDocumentBillings = [...documentBillings];
        const targetBilling = { ...newDocumentBillings[index], ...target };

        newDocumentBillings[index] = targetBilling;

        setDocumentBillings(newDocumentBillings);
    };

    const deleteDocument = (id) => {
        setDocuments([]);
        setDocumentBillings(state => state.filter(x => x.documentId !== id));
        setDocumentsFilter({
            ...documentsFilter,
            documents: documentsFilter.documents.filter(x => x !== id),
        });
    };

    const getItems = async () => {
        if (isLoadingInfo || isLoading) return;

        try {
            setIsLoading(true);
            const data =
                await BillingsServices.getBillingProducts(billingId, {
                    ...filter,
                    orderId,
                    documentsIds: documentsFilter.documents,
                });
            setVirtualTotals(data.totals);
            setData(data);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchInfo = async () => {
        try {
            setIsLoadingInfo(true);
            setLoading(true);

            const orderData = await OrdersService.getOrderById(orderId);

            setOrder(orderData);

            if (!isNewBilling) {
                const billingData = await BillingsServices.getBillingById(billingId);
                setBilling(billingData);
                if (billingData.documentsIds) {
                    setDocumentsFilter({
                        ...documentsFilter,
                        documents: billingData.documentsIds,
                    });
                }
                if (billingData?.billings?.length) {
                    setDocumentBillings(state => {
                        const newState = state.filter(x => !billingData.billings.find(y => y.number === x.number));

                        return [...newState, ...billingData.billings];
                    });
                }

                if (billingData.rateioFrete) {
                    setCanEdit({
                        ...canEdit,
                        rateioFrete: true,
                    });
                    setFilter(state => ({
                        ...state,
                        rateioFrete: billingData.rateioFrete,
                    }));
                }
            }
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoadingInfo(false);
            setLoading(false);
        }
    };

    const fetchDocumentsInfo = async () => {
        try {
            setIsLoading(true);
            setLoading(true);

            const documentsData = await DocumentsServices.getDocuments(documentsFilter);
            const documentBillingsData = documentsData.records.reduce((a, b) => [...a, ...b.billings.map(x => ({ ...x, documentId: b.id, total: formatCurrency(`${x.total}`) }))], []);

            setDocuments(documentsData);
            setDocumentBillings(state => [...state.filter(x => !documentsData.records.some(y => y.id === x.documentId)), ...documentBillingsData]);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch documentsInfo', err, null, title);
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!documentBillings.length) {
            hasError = true;
            setInfoModal({
                title: 'Faturamento',
                style: InfoModalStyle.INFO,
                message: 'Adicione pelo menos um boleto ao faturmento!',
                show: true,
            });
        } else {
            if (documentBillings.some(x => !x.billingDate || !x.total)) {
                hasError = true;
                setInfoModal({
                    title: 'Faturamento',
                    style: InfoModalStyle.INFO,
                    message: 'Preencha todos os campos dos boletos corretamente!',
                    show: true,
                });
            }
        }

        return hasError;
    };

    const saveBilling = async () => {
        if (hasValidationErrors()) return;

        try {
            setLoading(true);
            setIsLoading(true);
            const data = {
                customProducts,
                rateioFrete: filter.rateioFrete,
                documentsIds: documentsFilter.documents,
                billings: documentBillings.map(x => ({ ...x, total: extractCurrencyNumber(x.total) })),
                orderId: order.id,
            };

            if (isNewBilling) {
                await BillingsServices.create(data);
                setInfoModal({
                    title: 'Faturamento',
                    message: 'Faturamento realizado para o pedido!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => navigate(getOrderDetailRoute(orderId)),
                });
            } else {
                await BillingsServices.update(billingId, data);
                setInfoModal({
                    title: 'Faturamento',
                    message: 'Faturamento atualizado para o pedido!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }

            getItems();
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    useEffect(() => {
        if (documentsFilter.documents.length) {
            setSearchParams({
                documentsIds: documentsFilter.documents.join(';'),
            });
            fetchDocumentsInfo();
        }
    }, [documentsFilter, searchParams]);

    useEffect(() => {
        if (!canEdit.rateioFrete) {
            setFilter(state => ({ ...state, rateioFrete: 0 }));
        }
    }, [canEdit]);

    useEffect(() => {getItems();}, [filter, documentsFilter]);

    useEffect(() => {updateVirtualItems();}, [data.records, customProducts]);

    useEffect(() => {handleVirtualTotals();}, [virtualRecords]);

    useEffect(() => {
        setDocumentsFilter({
            ...documentsFilter,
            documents: searchParams.get('documentsIds')?.split(';').filter(Number).map(Number) || [],
        });
    }, [searchParams]);

    return (
        <>
            <div className={'purchase-detail order-billing'}>
                <div className={'crud-list'}>
                    <ScreenHeader
                        title={'Faturamento'}
                        breadcrumbs={[
                            { name: 'Compras', route: '/' },
                            {
                                name: 'Pedidos',
                                route: getOrderListRoute(),
                            },
                            {
                                name: 'Faturamento',
                                route: getOrderBillingRoute(),
                            },
                        ]}
                        backRoute={getOrderDetailRoute(orderId)}
                        hideStore
                    />
                    <OrderDetailHeaderTable
                        data={order}
                        virtualTotal={order.total}
                        isLoading={isLoading}
                    />
                    <div className={'row justify-content-end'}>
                        <div className={'col-7 d-flex align-items-center'}>
                            {
                                [OrderStatusEnum.CONFIRMADO].includes(order.status) && (

                                    <>
                                        <FieldCheckbox
                                            className={'mb-0'}
                                            type={'checkbox'}
                                            inputs={[
                                                {
                                                    value: canEdit.noDocument,
                                                    label: 'Editar Não faturado',
                                                    onChange: () => {
                                                        setCanEdit({
                                                            ...canEdit,
                                                            noDocument: !canEdit.noDocument,
                                                        });
                                                    },
                                                    checked: canEdit.noDocument,
                                                },
                                                {
                                                    value: canEdit.ipi,
                                                    label: 'Editar IPI',
                                                    onChange: () => {
                                                        setCanEdit({
                                                            ...canEdit,
                                                            ipi: !canEdit.ipi,
                                                        });
                                                    },
                                                    checked: canEdit.ipi,
                                                },
                                                {
                                                    value: canEdit.icms,
                                                    label: 'Editar ICMS',
                                                    onChange: () => {
                                                        setCanEdit({
                                                            ...canEdit,
                                                            icms: !canEdit.icms,
                                                        });
                                                    },
                                                    checked: canEdit.icms,
                                                },
                                                {
                                                    value: canEdit.frete,
                                                    label: 'Editar frete',
                                                    onChange: () => {
                                                        setCanEdit({
                                                            ...canEdit,
                                                            frete: !canEdit.frete,
                                                            rateioFrete: false,
                                                        });
                                                    },
                                                    checked: canEdit.frete,
                                                },
                                                {
                                                    value: canEdit.rateioFrete,
                                                    label: 'Rateio frete:',
                                                    onChange: (e) => {
                                                        setCanEdit({
                                                            ...canEdit,
                                                            rateioFrete: !canEdit.rateioFrete,
                                                            frete: false,
                                                        });
                                                    },
                                                    checked: canEdit.rateioFrete,
                                                },
                                            ]}
                                        />
                                        {
                                            canEdit.rateioFrete && (
                                                <div className={'input-rateio-frete'}>
                                                    <FieldNumber
                                                        fieldGroup={false}
                                                        required={true}
                                                        thousandsSeparator={true}
                                                        decimalLimit={2}
                                                        value={filter.rateioFrete}
                                                        onBlur={({ target }) => setFilter(state => ({ ...state, rateioFrete: Number(target.value) }))}
                                                    />
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                        <div className={'col-3'}>
                            <FieldTextSearch
                                label={
                                    '<em>Buscar por <strong>produto</strong> [ F2 ]</em>'
                                }
                                onChangeDebounce={() =>
                                    setFilter(state => ({
                                        ...state,
                                        product: search.product,
                                    }))
                                }
                                onChange={(e) =>
                                    setSearch({
                                        ...search,
                                        product: e.target.value,
                                    })
                                }
                                value={search.product}
                                className={'text_filter'}
                            />
                        </div>
                        <div className={'col-2 update-purchase'}>
                            {
                                [OrderStatusEnum.CONFIRMADO].includes(order.status) && (
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                        color={ButtonColor.BUTTON_COLOR_GREEN}
                                        onClick={() => setShowImportOrderDocumentModal(true)}
                                    >
                                        {'Importar XML [F9]'}
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                    <div className={'mt-16'}>
                        <Tabs.Root
                            defaultValue={filters.status}
                            className={'tabs_container'}
                            onValueChange={handleFilterTabChange}
                        >
                            <Tabs.List
                                className={'tabs_list'}
                                aria-label={'Manage your account'}
                            >
                                {Object.values(OrderBillingFilterTabEnum).map(
                                    (item, index) => {
                                        return (
                                            <Tabs.Trigger
                                                key={index}
                                                className={'tabs_trigger'}
                                                value={item}
                                            >
                                                {`${OrderBillingFilterTabText(item)}`}
                                            </Tabs.Trigger>
                                        );
                                    })}
                            </Tabs.List>
                        </Tabs.Root>
                        <OrderBillingTable
                            data={virtualRecords}
                            filter={filter}
                            isLoading={isLoading}
                            updateItem={updateCustomProducts}
                            canEdit={canEdit}
                            totals={virtualTotals}
                        />
                        <Pagination
                            page={data?.page}
                            pageSize={data?.pageSize}
                            count={data?.count}
                            recordCount={data?.records?.length || 0}
                            onPageChange={(page) => setFilter(state => ({ ...state, page }))}
                        />
                    </div>
                    <div className={'billing-totals mt-30 mb-30'}>
                        <div className={'d-flex'}>
                            <p className={'mr-42'}>{'Linhas '}<strong>{data?.count}</strong></p>
                            <p>{'Itens '}<strong>{virtualTotals.countItems}</strong></p>
                        </div>
                        <p><strong>{`Total ${formatValue(data.total)}`}</strong>{' | '}<em>{'Diferença do valor pedido '}<span>{`${formatValue(virtualTotals.totalFinal - data.total)}`}</span></em></p>
                    </div>

                    <div className={'mb-20'}>
                        <OrderBillingDocumentsTable canSave={[OrderStatusEnum.CONFIRMADO].includes(order.status)} data={documents?.records} deleteDocument={deleteDocument} billing={billing} />
                        <div>
                            <Pagination
                                page={documents?.page}
                                pageSize={documents?.pageSize}
                                count={documents?.count}
                                recordCount={documents?.records?.length || 0}
                                onPageChange={(page) => setDocumentsFilter({ ...documentsFilter, page })}
                            />
                        </div>
                    </div>

                    <div>
                        <OrderBillingBillsTable canSave={[OrderStatusEnum.CONFIRMADO].includes(order.status)} data={documentBillings} updateItem={updateDocumentBilling} deleteItem={deleteDocumentBilling} />
                    </div>

                    <div className={'billing-totals mt-2 mb-30'}>
                        <div className={'d-flex'}>
                            {
                                [OrderStatusEnum.CONFIRMADO].includes(order.status) && (
                                    <a onClick={addDocumentBilling}>{'Adicionar novo boleto'}</a>
                                )
                            }
                        </div>
                        <p><strong>{`Total final ${formatValue(virtualTotals.totalFinal)}`}</strong>{' | '}<em>{'Saldo do valor abatido '}<span>{formatValue(virtualTotals.totalFinal - documentBillings.reduce((a, b) => a + extractCurrencyNumber(`${b.total}` || '0'), 0))}</span></em></p>
                    </div>

                    <div className={'row d-flex justify-content-end'}>
                        <div className={'col-6 align-right update-purchase'}>
                            {
                                [OrderStatusEnum.CONFIRMADO].includes(order.status) && (
                                    <>
                                        <Button
                                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                            className={'ml-10'}
                                            color={ButtonColor.BUTTON_COLOR_GRAY}
                                            onClick={cancelBilling}
                                        >
                                            {'Cancelar faturamento [F9]'}
                                        </Button>
                                        <Button
                                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                            className={'ml-10'}
                                            color={ButtonColor.BUTTON_COLOR_GREEN}
                                            onClick={saveBilling}
                                        >
                                            {isNewBilling ? 'Faturar [F9]' : 'Editar faturamento [F9]'}
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ImportOrderDocumentModal
                show={showImportOrderDocumentModal}
                cnpj={order?.supplierCnpj}
                orderId={order?.id}
                selectedDocumentsList={documentsFilter.documents}
                onCancel={() => {
                    setShowImportOrderDocumentModal(false);
                }}
                onConfirm={(ids) => {
                    setShowImportOrderDocumentModal(false);
                    setDocumentsFilter({
                        ...documentsFilter,
                        documents: [...union(documentsFilter.documents, ids)],
                    });
                }}
            />
        </>
    );
}

export function getOrderBillingRoute(orderId, billingId) {
    if (orderId && billingId) {
        return `/compras/pedidos/${orderId}/faturamento/${billingId}`;
    }

    return '/compras/pedidos';
}
