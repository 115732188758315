const blur = (text) => {
    return '*****';
};

const anonymize = () => {
    return 'Anonimizado pelo Sistema';
};

const sensitive = {
    blur,
    anonymize,
};

export default sensitive;
