import FieldLiveSearch from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as NcmService from '../services/NcmService';
import { OrderByNcmEnum } from 'erva-doce-common';

function FieldNcm({
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await NcmService.getNcmList(0, uuid,
            OrderByNcmEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await NcmService.getNcmList(page, searchStr,
            OrderByNcmEnum.NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            label={'NCM'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldNcm;
