import { useEffect, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconAdd, IconAdd2x, IconEdit, IconEdit2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import Table from '../../components/Table';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getCouponFormRoute } from './CupomForm';
import { useNavigate } from 'react-router';
import { getAllCoupons } from '../../services/CouponService';
import Pagination from '../../components/Pagination';

const FILTERS = {
    search: null,
    page: 0,
};

export default function Cupons() {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);
    const [filter, setFilter] = useState(FILTERS);

    useEffect(() => {
        const getAll = async () => {
            try {
                const res = await getAllCoupons(filter);
                setData(res);
            } catch (error) { }
        };

        getAll();
    }, []);


    const title = 'Cupons';
    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Comercial', route: getDashboardRoute() },
                        { name: title, route: getCuponsRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'}>
                    </div>
                    <div className={'hide-mobile col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => handleRowClick(getCouponFormRoute())}
                            className={'w-100'}
                        >
                            {'Novo Cupom'}
                        </Button>
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                name: 'Código',
                                align: 'left',
                                width: '20%',
                            },
                            {
                                name: 'Status',
                                align: 'center',
                            },
                            {
                                name: 'Tipo',
                                align: 'center',
                            },
                            {
                                name: 'Data de criação',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'center',
                            },
                        ]}
                    >
                        {data?.records?.map((cupon, index) => (

                            <tr key={index}>
                                <td>
                                    {cupon.code}
                                </td>
                                <td className={'text-center'}>
                                    {cupon.isActive ? 'Ativo' : 'Inativo'}
                                </td>
                                <td className={'text-center'}>
                                    {cupon.typeFixed ? 'Fixo' : 'Percentual'}
                                </td>
                                <td className={'text-center'}>
                                    {cupon.dtCriação}
                                </td>
                                <td className={'text-center'}>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => handleRowClick(getCouponFormRoute(cupon.id))}
                                    >
                                        <img
                                            src={IconEdit}
                                            srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                            alt={'Editar cupom'}
                                            title={'Editar cupom'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </Table>
                    <div className={'w-100 d-flex justify-content-center'}>
                        <Pagination
                            page={data?.page}
                            pageSize={data?.pageSize}
                            count={data?.count}
                            recordCount={data?.records?.length}
                            onPageChange={page => setFilter({ ...filter, page })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export function getCuponsRoute() {
    return '/comercial/cupons';
}
