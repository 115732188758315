const blur = (date) => {
    if (!date) return '**/**/****';

    const year = date.split('/')[2];

    return `**/**/${year}`;
};

const anonymize = () => {
    return '01/01/1970';
};

const date = {
    blur,
    anonymize,
};

export default date;
