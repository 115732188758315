import Section from '../../components/Section';
import './PaymentMethodTableScss.scss';
import { formatValue } from '../../util/utilCurrency';

export default function PaymentMethodTableComponent({ data }) {
    const renderPaymentMethod = (label, value) => (
        <div className={'sales-by-mode-table-content'}>
            <span>{label}</span>
            <span>{`${formatValue({ value })}`}</span>
        </div>
    );

    return (
        <div className={'payment-method-table-container'}>
            <Section title={'Informações das Vendas'}>
                {data?.length > 0 && (
                    <>
                        <div className={'gd box-container-movement-page'}>
                            {data?.map((item, index) => (
                                <div
                                    className={
                                        'gd-col gd-col-3 sales-by-mode-table'
                                    }
                                    key={index}
                                >
                                    <div
                                        className={'sales-by-mode-table-title'}
                                    >
                                        <span>{item.name}</span>
                                        <span>{`Total: R$ ${formatValue({
                                            value: item.total,
                                        })}`}</span>
                                    </div>
                                    <div className={'sales-by-mode-table-body'}>
                                        {item.name === 'DINHEIRO' ? (
                                            renderPaymentMethod(
                                                'Dinheiro',
                                                item.DINNER
                                            )
                                        ) : (
                                            <>
                                                {renderPaymentMethod(
                                                    'PIX',
                                                    item.PIX
                                                )}
                                                {renderPaymentMethod(
                                                    'Crédito',
                                                    item.CREDIT_CARD
                                                )}
                                                {renderPaymentMethod(
                                                    'Débito',
                                                    item.DEBIT_CARD
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {data?.length === 0 && (
                    <>
                        <div className={'is-empty-data'}>
                            <p> {'Não há dados para exibir'}</p>
                        </div>
                    </>
                )}
            </Section>
        </div>
    );
}