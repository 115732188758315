import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getOrderListRoute } from '../../order-list/OrderList';
import { getOrderDetailRoute } from '../order-detail/OrderDetail';
import OrderDetailHeaderTable from '../order-detail/OrderDetailHeaderTable';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as OrdersService from '../../../services/OrdersService';
import * as OrderReceptionService from '../../../services/OrderReceptionService';
import { useNavigate, useParams } from 'react-router';
import FieldTextSearch from '../../../components/FieldTextSearch';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import FieldDate from '../../../components/FieldDate';
import FieldText from '../../../components/FieldText';
import { OrderBillingTypeText } from 'erva-doce-common';
import FieldCurrency, { formatCurrency } from '../../../components/FieldCurrency';
import OrderDetailReceiveTable from './OrderDetailReceiveTable';

const INITIAL_FILTER = {
    page: 0,
    product: '',
};

export default function OrderDetailReceive() {
    const title = 'Recebimento';
    const inputSearchRef = useRef();
    const { orderId, receiveId } = useParams();
    const navigate = useNavigate();
    const { setLoading, backendConnectionError } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState({});
    const [orderReceive, setOrderReceive] = useState({});
    const [data, setData] = useState({});

    const updateFilter = (name, value) => {
        setFilter(state => ({ ...state, [name]: value }));
    };

    const fetchInfo = async () => {
        try {
            setLoading(true);
            setIsLoading(true);

            const orderData = await OrdersService.getOrderById(orderId);
            const data = await OrderReceptionService.getByUUID(receiveId);

            setOrderReceive(data);

            setOrder(orderData);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };

    const fetchReceives = async () => {
        try {
            setIsLoading(true);
            const data = await OrderReceptionService.getItemsByOrder(orderId, receiveId, filter);

            setData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [orderId]);

    useEffect(() => {
        fetchReceives();
    }, [receiveId, filter]);

    return (
        <>
            <div className={'purchase-detail order-billing'}>
                <div className={'crud-list'}>
                    <ScreenHeader
                        title={'Recebimento'}
                        breadcrumbs={[
                            { name: 'Compras', route: '/' },
                            {
                                name: 'Pedidos',
                                route: getOrderListRoute(),
                            },
                            {
                                name: 'Recebimento',
                                route: getOrderDetailReceiveRoute(),
                            },
                        ]}
                        backRoute={getOrderDetailRoute(orderId)}
                        hideStore
                    />
                    <OrderDetailHeaderTable
                        data={order}
                        virtualTotal={order.total}
                        isLoading={isLoading}
                    />
                </div>
                <div className={'row mb-32'}>
                    <div className={'col-6 col-md-4'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={
                                '<em>Buscar por <strong>EAN</strong> ou <strong>nome</strong> do produto</em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('product', search)
                            }
                            onChange={(e) =>
                                setSearch(e.target.value)
                            }
                            value={search}
                            className={'text_filter'}
                        />
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <OrderDetailReceiveTable
                        data={data?.records}
                        isLoading={isLoading}
                        filter={filter}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
                <div className={'billing-totals mt-30 mb-30'}>
                    <div className={'d-flex'}>
                        <p className={'mr-42'}>{'Linhas '}<strong>{data?.count || 0}</strong></p>
                        <p>{'Itens '}<strong>{data?.countItems || 0}</strong></p>
                    </div>
                </div>
                <div className={'row mt-32 mb-32'}>
                    <div className={'col-12'}>
                        <Table
                            loading={isLoading}
                            columns={[
                                {
                                    name: 'Vencimento',
                                    width: '10%',
                                },
                                {
                                    name: 'Tipo do boleto',
                                    width: '20%',
                                },
                                {
                                    name: 'Valor',
                                    align: 'center',
                                    width: '10%',
                                },

                                {
                                    name: 'Numeração',
                                    align: 'center',
                                    width: '10%',
                                },
                            ]}
                        >
                            {orderReceive?.billings?.length ? orderReceive?.billings?.map((billing, index) => (
                                <tr key={index}>
                                    <td className={'count-selected text-start'}>
                                        <FieldDate
                                            fieldGroup={false}
                                            readOnly={true}
                                            value={billing.dueDate || ''}
                                        />
                                    </td>
                                    <td className={'count-selected text-center'}>
                                        <FieldText
                                            readOnly
                                            textAlign={'left'}
                                            fieldGroup={false}
                                            required={false}
                                            value={billing.type ? OrderBillingTypeText(billing.type) : '-'}
                                        />
                                    </td>
                                    <td className={'count-selected text-center'}>
                                        <FieldCurrency
                                            type={'text'}
                                            value={formatCurrency(`${billing?.total || ''}`, true)}
                                            disabled={true}
                                            fieldGroup={false}
                                            required
                                        />
                                    </td>
                                    <td className={'count-selected text-center'}>
                                        <FieldText
                                            fieldGroup={false}
                                            required={true}
                                            value={billing.documentNumber}
                                            readOnly={true}
                                        />
                                    </td>
                                </tr>
                            )) : <tr><td colSpan={17} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}


export function getOrderDetailReceiveRoute(orderId, receiveId) {
    if (orderId && receiveId) {
        return `/compras/pedidos/${orderId}/recebimento/${receiveId}`;
    }

    return '/compras/pedidos';
}
