import React, { useContext, useEffect, useRef, useState } from 'react';
import FieldSelect from '../../components/FieldSelect';
import Table from '../../components/Table';
import { formatValue } from '../../util/utilCurrency';
import './CashClosingScss.scss';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';

import {
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
} from '../../components/images';
import Section from '../../components/Section';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getPanelPage } from './Panel';
import FieldDate from '../../components/FieldDate';
import CashFlowSummaryComponent from './CashFlowSummary';
import PaymentMethodTableComponent from './PaymentMethodTable';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { useNavigate, useParams } from 'react-router';
import { getDateToDayForInputHtml } from '../../util/utilDate';
import CalculateCash from './CalculateCash';
import * as MovementService from '../../services/MovementService';

const MACHINE_OPTIONS = [
    { value: 'PIX', label: 'PIX' },
    { value: 'Crédito', label: 'Crédito' },
    { value: 'Débito', label: 'Débito' },
];

export default function CashClosingPage() {
    const title = 'Fechamento de caixa';
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const [rows, setRows] = useState([
        { id: Date.now(), machine: '', operation: '', total: 0 },
    ]);
    const [total, setTotal] = useState(0);
    const [valueDate, setValueDate] = useState('');
    const [optionsMachine, setOptionsMachine] = useState([]);
    const { user, addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const navigate = useNavigate();
    const { id } = useParams();

    function formatedDate({ type }) {
        if (valueDate === '') {
            return type;
        }
        const [aaaa, mm, dd] = valueDate.split('-');
        return `${dd}/${mm}/${aaaa} - ${type}`;
    }

    const addRow = () => {
        const newRow = { id: Date.now(), machine: '', operation: '', total: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const searchPriceForMachine = (machine, operation) => {
        const selectedMachine = optionsMachine?.find(
            (item) => item.value === machine
        );
        if (selectedMachine && selectedMachine.price) {
            const { pix, credito, debito } = selectedMachine.price;
            switch (operation) {
            case 'PIX':
                return pix;
            case 'Crédito':
                return credito;
            case 'Débito':
                return debito;
            default:
                return 0;
            }
        }
        return 0;
    };

    const handleMachineChange = (id, machine) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                const total = searchPriceForMachine(machine, row.operation);
                return { ...row, machine, total };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const handleOperationChange = (id, operation) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                const total = searchPriceForMachine(row.machine, operation);
                return { ...row, operation, total };
            }
            return row;
        });
        setRows(updatedRows);
    };

    useEffect(() => {
        const some = rows.reduce((acc, row) => {
            return (acc += row.total);
        }, 0);
        setTotal(some);
    }, [rows]);

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;

        f3Hotkey = addHotkey('F3', () => {
            buttonDeleteRef.current?.click();
        });

        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        const date = getDateToDayForInputHtml();
        setValueDate(date);
    }, []);

    useEffect(() => {
        if (valueDate) {
            getMachineValues();
        }
    }, [valueDate]);

    const redirectURL = () => {
        navigate('/');
    };

    async function getMachineValues() {
        try {
            const userId = user?.cashier?.id ? user?.cashier?.id : id;
            if(!userId) {
                redirectURL();
                return;
            }

            const result = await MovementService.getMachineValues({
                date: valueDate,
                userId,
            });

            const data = result.map((item) => {
                const { name, credito, debito, pix } = item;
                return {
                    value: name,
                    label: name,
                    price: {
                        pix: pix || 0,
                        credito: credito || 0,
                        debito: debito || 0,
                    },
                };
            });

            setOptionsMachine(data);
        } catch (error) {
            console.error(
                'Deu erro ao trazer os dados da máquina - cash closing',
                error
            );
        }
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Caixa', route: '/' },
                    { name: title, route: getPanelPage() },
                ]}
                backRoute={getPanelPage()}
            />

            <div>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-10'}></div>
                    <div className={'gd-col gd-col-2'}>
                        <FieldDate
                            onChange={({ target }) =>
                                setValueDate(target.value)
                            }
                            value={valueDate}
                        />
                    </div>
                </div>
            </div>

            <Section
                title={formatedDate({ type: 'Reforço, Sangria e Trocas' })}
            >
                <CashFlowSummaryComponent />
            </Section>

            <Section title={formatedDate({ type: 'Conferência de Canhotos' })}>
                <div className={'box-table'}>
                    <div className={'facade'}>
                        <div>
                            <p>
                                <strong>{`Itens: ${rows?.length}`}</strong>
                            </p>
                        </div>
                        <div>
                            <p>
                                <i>
                                    <strong>
                                        {`Total geral de cartões ${formatValue({
                                            value: total,
                                        })}`}
                                    </strong>
                                </i>
                            </p>
                        </div>
                    </div>
                    <div className={'table-content'}>
                        <Table
                            columns={[
                                { name: 'Operadora', align: 'center' },
                                { name: 'Tipo operação', align: 'center' },
                                { name: 'Valor total', align: 'center' },
                                { name: '', align: 'center' },
                                { name: 'Excluir linha', align: 'center' },
                            ]}
                        >
                            {rows?.map((row) => (
                                <tr key={row.id}>
                                    <td className={'center'}>
                                        <FieldSelect
                                            options={optionsMachine}
                                            placeholder={
                                                'Selecione a maquininha'
                                            }
                                            disableDefaultOption={false}
                                            onChange={(e) =>
                                                handleMachineChange(
                                                    row.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td className={'center'}>
                                        <FieldSelect
                                            options={MACHINE_OPTIONS}
                                            placeholder={'Selecione a operação'}
                                            disableDefaultOption={false}
                                            onChange={(e) =>
                                                handleOperationChange(
                                                    row.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td className={'center'}>
                                        <p>{`${formatValue({
                                            value: row.total,
                                        })}`}</p>
                                    </td>
                                    <td></td>
                                    <td className={'center'}>
                                        <Button
                                            className={'transparent'}
                                            onClick={() => removeRow(row.id)}
                                        >
                                            <img
                                                src={IconButtonRemoveSvg}
                                                srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                alt={'Excluir'}
                                                title={'Excluir'}
                                                style={{
                                                    width: '14px',
                                                }}
                                            />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </Table>
                        <div className={'box-button'}>
                            <Button
                                color={ButtonColor.BUTTON_COLOR_WHITE}
                                className={'button-new-machine'}
                                onClick={addRow}
                            >
                                {'+ Adicionar maquina'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <PaymentMethodTableComponent />
                </div>
            </Section>

            <CalculateCash />

            <div className={'controls mt-42'}>
                {!id && (
                    <div className={'row'}>
                        <div className={'col-8'} />
                        <div className={'col-2'}>
                            <Button
                                className={'w-100'}
                                ref={buttonDeleteRef}
                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                onClick={() => {
                                    navigate(getPanelPage());
                                }}
                            >
                                {'Sair [F3]'}
                            </Button>
                        </div>
                        <div className={'col-2'}>
                            <Button
                                className={'w-100'}
                                ref={buttonSaveRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={() => {
                                    navigate(getCashClosingPage());
                                }}
                            >
                                {'Feixar caixa [F6]'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export function getCashClosingPage(id) {
    if (id) {
        return `/vendas/fechamento/${id}`;
    }
    return '/vendas/fechamento';
}
