import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getOrderReceiveRoute } from '.';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import ScreenHeader from '../../components/logged/ScreenHeader';

import * as OrderReceptionService from '../../services/OrderReceptionService';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import { getOrderReceiveDetailRoute } from './OrderReceiveDetail';
import ConfirmProductFoundModal from './ConfirmProductFoundModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';

import './HandleScanProducts.scss';
import classNames from 'classnames';
import useOutsideClick from '../../hooks/useOutsideClick';

const INITIAL_FILTER = {
    volume: 1,
};

export default function HandleScanProducts() {
    const title = 'Receber produtos';
    const navigate = useNavigate();
    const { uuid } = useParams();
    const inputSearchRef = useRef();
    const selectVolumeRef = useRef();
    const { backendConnectionError, setInfoModal, setLoading } = useContext(EnvironmentContext);

    const [data, setData] = useState([]);
    const [receive, setReceive] = useState({});
    const [formError, setFormError] = useState({});
    const [productFound, setProductFound] = useState(null);
    const [showConfirmProductModal, setShowConfirmProductModal] = useState(false);
    const [showSelectVolume, setShowSelectVolume] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [virtualTotalVolume, setVirtualTotalVolume] = useState(1);

    useOutsideClick(selectVolumeRef, () => setShowSelectVolume(false));

    const updateFilter = (value) => {
        setFilter(state => ({
            ...state,
            ...value,
        }));
    };

    const toggleShowSelectVolume = () => {
        setShowSelectVolume(!showSelectVolume);
    };

    const getInfo = async () => {
        try {
            if (uuid) {
                const info = await OrderReceptionService.getByUUID(uuid);
                const products = await OrderReceptionService.getItemsScan(uuid, filter);

                if (info.finishedAt) {
                    navigate(getOrderReceiveDetailRoute(uuid));
                }

                setReceive(info);
                setData(products);
                setVirtualTotalVolume(products.totalVolumes || 1);
            }
        } catch(err) {
            console.log(err);
            navigate(getOrderReceiveRoute());
        }
    };

    const resetForm = () => {
        setProductFound(null);
        setSearchProduct('');
        setShowConfirmProductModal(false);
        inputSearchRef?.current?.focus();
    };

    const fetchProductsByBarCode = async () => {
        const notFound = () => {
            resetForm();
            setFormError({
                search: '* Produto não encontrado!',
            });
        };

        try {
            if (searchProduct) {
                const prod = await OrderReceptionService.getProductByBarCode(searchProduct);

                if (prod?.uuid) {
                    setProductFound(prod);
                    setFormError({});
                    setShowConfirmProductModal(true);
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                    });
                    notFound();
                }
            }
        } catch (err) {
            console.log(err);
            notFound();
        }
    };

    const registerScanProduct = async ({ count }) => {
        try {
            setLoading(true);

            await OrderReceptionService.createItemScan(uuid, {
                count,
                product: productFound.uuid,
                volume: filter.volume,
            });

        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to register scan product', err, null, title);
        } finally {
            setLoading(false);
            getInfo();
            resetForm();
        }
    };

    useEffect(() => {
        getInfo();
        inputSearchRef?.current?.focus();
    }, [filter]);

    return (
        <>
            <div className={'order-receive stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: 'Recebimento de mercadorias', route: getOrderReceiveRoute() },
                        { name: title, route: getHandleScanProductsRoute() },
                    ]}
                    backRoute={getOrderReceiveDetailRoute(uuid)}
                    hideStore
                />
                <div className={'row mb-32'}>
                    <div className={'col-6'}>
                        <p>{'Volume selecionado: '}{`${filter.volume}`.padStart(2, 0)}{' de '}{`${virtualTotalVolume}`.padStart(2, 0)}</p>
                    </div>
                    <div className={'col-6'}>
                        <div ref={selectVolumeRef} className={'select-volume'} onClick={toggleShowSelectVolume}>
                            <p className={'text-end'}>{'Selecionar Volume'}</p>
                            <div className={classNames('select-volume__items', { active: showSelectVolume })}>
                                <ul>
                                    {
                                        Array.from({ length: virtualTotalVolume }).map((_, index) => (
                                            <li key={index} className={'mb-8'} onClick={() => updateFilter({ volume: index + 1 })}>{'Volume '}{index + 1}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <FieldTextSearch
                        fieldGroup={true}
                        ref={inputSearchRef}
                        label={
                            '<em>Busque um produto por <strong>ean</strong></em> [F1]'
                        }
                        value={searchProduct}
                        onChangeDebounce={fetchProductsByBarCode}
                        onChange={({ target }) => {
                            setSearchProduct(target.value);
                        }}
                        validationError={formError?.search}
                        className={'text_filter'}
                    />
                </div>
                <div className={'row mb-32'}>
                    <div className={'col-12'}>
                        {
                            data.records?.length ?
                                data.records.map((product) => (
                                    <div className={'product-card mb-16'} key={product.id}>
                                        <p className={'mb-8'}>{'EAN '}<strong>{product?.barCodes?.find(x => x.main)?.barCode || product?.barCodes?.[0].barCode || '-'}</strong>{', Qtd '}<strong>{product.count}</strong>{', Volume '}<strong>{product.volume}</strong></p>
                                        {product.name}
                                    </div>
                                ))
                                : (
                                    <p className={'text-center'}>{'Nenhum produto registrado no volume'}</p>
                                )
                        }
                    </div>
                </div>
                <div className={'row mb-8'}>
                    <div className={'col-12 col-md-4'}>
                        <Button
                            className={'w-100'}
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            onClick={() => navigate(getOrderReceiveDetailRoute(uuid))}
                            disabled={!receive}
                        >
                            {'Finalizar recebimento'}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12 col-md-4'}>
                        <Button
                            className={'w-100'}
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            onClick={() => {
                                updateFilter({ volume: (virtualTotalVolume) + 1 });
                                setVirtualTotalVolume(state => state + 1);
                            }}
                            disabled={!receive}
                        >
                            {'Novo volume'}
                        </Button>
                    </div>
                </div>
            </div>
            <ConfirmProductFoundModal
                show={showConfirmProductModal}
                productFound={productFound}
                filter={filter}
                receive={receive}
                onConfirm={registerScanProduct}
                onCancel={resetForm}
            />
        </>
    );
}

export function getHandleScanProductsRoute(uuid) {
    if (uuid) {
        return `/compras/recebimento-de-mercadorias/receber-produtos/${uuid}`;
    } else {
        return '/compras/recebimento-de-mercadorias/receber-produtos';
    }
}
