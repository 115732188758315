import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { formatValue } from '../../util/formatValue';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import ProductDevolutionList from '../../components/ProductDevolutionList';

function DevolutionSaleModal({
    show,
    onCancel,
    onConfirm,
    formData,
    salesForm,
    formDevolution,
    updateFormDevolution,
    ...props
}){
    const [formError, setFormError] = useState();

    function cancel() {
        onCancel();
    }

    function confirm() {
        onConfirm();
    }
    
    return (
        <div className={'sales-payment-modal'}>
            <SimpleConfirmModal
                // I18N
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                confirmText={'Concluir'}
                disabled={ !formDevolution?.products?.some(product => product.amount > 0) }
            >
                <div className={'gd'}>
                    <div className={'gd-col-12  title'}>
                        {'Finalizar Devolução'}
                    </div>
                </div>
                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <ProductDevolutionList
                            formDevolution={formDevolution}
                            updateFormDevolution={updateFormDevolution}
                        />
                    </div>
                </div>
            </SimpleConfirmModal>
        </div>
    );
}

export default DevolutionSaleModal;