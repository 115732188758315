export enum OrderBillingTypeEnum {
  ALL = "ALL",
  DOCUMENT = "DOCUMENT",
  NO_DOCUMENT = "NO_DOCUMENT",
}

export function OrderBillingTypeText(type: OrderBillingTypeEnum | string) {
  switch (type) {
    case OrderBillingTypeEnum.ALL:
      return "Não Faturado e Nota Fiscal";
    case OrderBillingTypeEnum.DOCUMENT:
      return "Nota Fiscal";
    case OrderBillingTypeEnum.NO_DOCUMENT:
      return "Não Faturado";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
