import * as Sentry from '@sentry/react';
import axios from 'axios';
import { OrderBySalesEnum } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as SalesService from '../../services/SalesService';
import SalesFilterModal from './SalesFilterModal';
import { getSalesFormRoute } from './SalesForm';
import { auto } from '@popperjs/core';
import hideData from '../../util/hideData';

export default function Sales() {
    const [sales, setSales] = useState({});
    const [salesLoading, setSalesLoading] = useState(false);
    const [haveFetchSalesError, setHaveFetchSalesError] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [filter, setFilter] = useState({
        order: OrderBySalesEnum.DATE_DESC,
        search: null,
        cashiers: null,
        sellers: null,
        initialDate: null,
        finalDate: null,
        page: 0,
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        selectedStore,
    } = useContext(EnvironmentContext);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
        };
    }, []);

    const fetchSales = useCallback(async () => {
        try {
            setSalesLoading(true);
            const cancelToken = axios.CancelToken.source().token;

            const response = await SalesService.getSales({ ...filter, cancelToken });

            setSales(response);
        } catch (e) {
            setHaveFetchSalesError(true);

            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        } finally {
            setSalesLoading(false);
            setHaveFetchSalesError(false);
        }
    }, [filter, selectedStore]);

    useEffect(() => {
        fetchSales();
    }, [fetchSales]);

    return haveFetchSalesError ? (
        <LoadError
            // I18N
            message={'Não foi possível carregar as vendas'}
            onTryAgain={() => fetchSales()}
        />
    ) : (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    // I18N
                    title={'Vendas'}
                    breadcrumbs={[
                        // I18N
                        { name: 'Vendas', route: getSalesRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={'<em>Buscar por <strong>Nº de venda</strong> ou <strong>CPF do cliente</strong> [F2]</em>'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}
                            style={{ marginLeft: auto }}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                            >
                                {/*I18N*/}
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    <Table
                        loading={salesLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                // I18N
                                name: 'N° Venda',
                            },
                            {
                                // I18N
                                name: 'Data/Hora',
                                sortAsc: OrderBySalesEnum.DATE_ASC,
                                sortDesc: OrderBySalesEnum.DATE_DESC,
                                onSortChange: (order) => setFilter({ ...filter, order })
                            },
                            {
                                // I18N
                                name: 'Vendedor',
                            },
                            {
                                // I18N
                                name: 'Operador',

                            },
                            {
                                // I18N
                                name: 'Cliente',

                            },
                            {
                                // I18N
                                name: 'Valor',
                            },
                            {
                                // I18N
                                name: 'CPF na nota?',
                            },
                            {
                                // I18N
                                name: 'NF',
                            },
                        ]}>
                        {sales.records && sales.records.map(sale => {
                            return (
                                <tr key={sale.uuid} onClick={() => handleRowClick(getSalesFormRoute(sale.uuid))}>
                                    <td>{sale.id}</td>
                                    <td>{new Date(sale.createdAt).toLocaleString('pt-br')} </td>
                                    <td>{sale.sellerUser?.name ?? '-'}</td>
                                    <td>{sale.cashierUser?.name ?? '-'}</td>
                                    <td>{sale.customerModel ? `${sale.customerModel?.name ? `${sale.customerModel?.name} - ` : ''}${hideData.cpf.blur(sale.customerModel?.cpf)}` : '-'}</td>
                                    <td>{sale.total?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}</td>
                                    <td>{sale?.cpfNote ? 'Sim' : 'Não'}</td>
                                    <td>{sale?.cfeModel?.chaveNfe || '-'}</td>
                                </tr>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={sales?.page}
                        pageSize={sales?.pageSize}
                        count={sales?.count}
                        recordCount={sales?.records?.length}
                        onPageChange={page => setFilter({ ...filter, page: page })}
                    />
                </div>
            </div>
            <SalesFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    const sellersNames = complementaryFilter.sellers.map(seller => seller.value);
                    const cashiersNames = complementaryFilter.cashiers.map(cashier => cashier.value);

                    setFilter({
                        ...filter,
                        cashiers: cashiersNames,
                        sellers: sellersNames,
                        initialDate: complementaryFilter.initialDate,
                        finalDate: complementaryFilter.finalDate
                    });
                }}
            />
        </>
    );
}

export function getSalesRoute() {
    //I18N
    return '/vendas/listagem';
}
