import { DateTime } from 'luxon';
import Table from '../../../components/Table';

export default function OrderDetailReceiveTable({
    data,
    filter,
    isLoading,
    handleSelectAll,
    selectAll,
}) {
    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            columns={[
                {
                    name: 'EAN',
                    align: 'center',
                },
                {
                    name: 'Produto',
                    width: '20%',
                },
                {
                    name: 'Qtd Recebida',
                    align: 'center',
                },
                {
                    name: 'Volume',
                    align: 'center',
                },
                {
                    name: 'Recebedor(a)',
                    align: 'center',
                },
                {
                    name: 'Data/Hora',
                    align: 'center',
                },
                {
                    name: 'Ações',
                    align: 'end',
                },
            ]}
        >
            {data?.map((product) => (
                <tr key={product.id}>
                    <td className={'text-center'}>{product?.barCodes?.find(x => x.main)?.barCode || product?.barCodes?.[0].barCode || '-'}</td>
                    <td>{product.name}</td>
                    <td className={'text-center'}>{product.count}</td>
                    <td className={'text-center'}>{product.volume}</td>
                    <td className={'text-center'}>{product.responsibleName}</td>
                    <td className={'text-center'}>{DateTime.fromISO(product.createdAt).toFormat('dd/MM/yyyy HH:mm')}</td>
                    <td>
                        <div className={'d-flex justify-content-end'}>

                        </div>
                    </td>
                </tr>
            ))}
        </Table>
    );
}
