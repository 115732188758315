import api from '../util/api';

export async function getByUUID(uuid) {
    return (
        await api.get(`/order-receives/${uuid}`)
    ).data;
}

export async function create(data) {
    return (
        await api.post('/order-receives', data)
    ).data;
}

export async function finish(uuid) {
    return (
        await api.put(`/order-receives/${uuid}/finish`)
    ).data;
}

export async function getItems(uuid, filter) {
    return (
        await api.get(`/order-receives/${uuid}/products`, {
            params: filter,
        })
    ).data;
}

export async function getItemsByOrder(orderId, uuid, filter) {
    return (
        await api.get(`/order-receives/${uuid}/orders/${orderId}/products`, {
            params: filter,
        })
    ).data;
}

export async function getItemsScan(uuid, filter) {
    return (
        await api.get(`/order-receives/scan/${uuid}/products`, {
            params: filter,
        })
    ).data;
}

export async function getAll(filters) {
    return (
        await api.get('/order-receives', {
            params: filters,
        })
    ).data;
}

export async function getAllByOrder(filters) {
    return (
        await api.get(`/order-receives/orders/${filters.orderId}`, {
            params: filters,
        })
    ).data;
}

export async function searchSuppliers(filters) {
    return (
        await api.get('/order-receives/search/suppliers', {
            params: filters,
        })
    ).data;
}

export async function searchBillings(filters) {
    return (
        await api.get('/order-receives/search/billings', {
            params: filters,
        })
    ).data;
}

export async function getProductByBarCode(barCode) {
    return (
        await api.get(`/order-receives/scan/products/bar-code/${barCode}`)
    ).data;
}

export async function createItemScan(uuid, data) {
    return (
        await api.post(`/order-receives/scan/${uuid}/products/`, data)
    );
}
