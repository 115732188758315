import * as Sentry from '@sentry/react';
import axios from 'axios';
import { OrderByProductsEnum } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductsService from '../../services/ProductsService';
import { formatValue } from '../../util/formatValue';
import ProductsFilterModal from './ProductFilterModal';
import { getProductFormRoute } from './ProductsForm';
import { IconCopy, IconProductFamily } from '../../components/images';
import './Products.scss';
import Pills from '../../components/Pills';
import { calculateMarginPercentage } from '../../util/calculateMarginPercentage';
import { getProductFamilyFormRoute } from '../products-families/ProductFamilyForm';

export default function Products() {
    const inputSearchRef = useRef();

    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(false);
    const [haveFetchProductsError, setHaveFetchProductsError] = useState(false);

    const [showFilter, setShowFilter] = useState(false);

    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        order: OrderByProductsEnum.NAME_ASC,
        search: null,
        page: 0,
        brand: null,
        family: null,
        category: null,
        subCategory: null,
        segmentation: null,
        cfop: null,
        icms: null,
        taxClassification: null,
        taxSituation: null,
        supplier: null,
        representative: null,
    });

    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);

    const handleCopyProduct = (uuid, e) => {
        e.stopPropagation();
        navigate(`/produtos/listagem/novo?uuid=${uuid}`);
    };

    const fetchProducts = useCallback(async () => {
        try {
            setProductsLoading(true);

            const cancelToken = axios.CancelToken.source().token;

            const response = await ProductsService.getProducts(
                filter.page,
                filter.search,
                filter.order,
                filter.family,
                filter.brand,
                filter.category,
                filter.subCategory,
                filter.segmentation,
                filter.cfop,
                filter.icms,
                filter.taxClassification,
                filter.taxSituation,
                filter.supplier,
                filter.representative,
                filter.product,
                cancelToken
            );
            setProducts(response);
        } catch (e) {
            setHaveFetchProductsError(true);

            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setProducts(null);
            }
        } finally {
            setProductsLoading(false);
            setHaveFetchProductsError(false);
        }
    }, [filter]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    useEffect(() => {
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
        };
    }, []);

    const handleRowClick = (route) => navigate(route);

    const handleIconFilterProductFamily = (event, product) => {
        event.stopPropagation();
        setFilter({
            ...filter,
            family: [product.familyModel.name],
        });
    };

    return haveFetchProductsError ? (
        <LoadError
            // I18N
            message={'Não foi possível carregar os produtos'}
            onTryAgain={fetchProducts}
        />
    ) : (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Listagem'}
                    breadcrumbs={[
                        // I18N
                        { name: 'Produtos', route: getDashboardRoute() },
                        // I18N
                        { name: 'Listagem', route: getProductsRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd field-group'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={
                                    '<em>Buscar produto por <strong>nome</strong> ou <strong>código de barras</strong> [F2]</em>'
                                }
                                onChange={({ target }) =>
                                    setFilter({
                                        ...filter,
                                        search: target.value,
                                    })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getProductFormRoute()}
                            >
                                {'Novo produto'}
                            </Button>
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                            >
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>
                    <div className={'gd align-right'}>
                        {filter && (
                            <Pills
                                pills={
                                    filter?.family?.map((x, index) => ({
                                        id: index,
                                        value: x,
                                    })) || []
                                }
                                onRemoveItem={(e) => {
                                    setFilter({
                                        ...filter,
                                        family: filter.family.filter(
                                            (x) => x !== e.value
                                        ),
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className={'table-scroll'}>
                    <Table
                        loading={productsLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                // I18N
                                name: 'Código barras',
                                sortAsc: OrderByProductsEnum.BARCODE_ASC,
                                sortDesc: OrderByProductsEnum.BARCODE_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                            },
                            {
                                // I18N
                                name: 'Nome do produto',
                                sortAsc: OrderByProductsEnum.NAME_ASC,
                                sortDesc: OrderByProductsEnum.NAME_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                            },
                            {
                                // I18N
                                name: 'Família do produto',
                                sortAsc: OrderByProductsEnum.FAMILY_NAME_ASC,
                                sortDesc: OrderByProductsEnum.FAMILY_NAME_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                                align: 'center',
                            },
                            {
                                // I18N
                                name: 'Custo/Margem',
                                sortAsc: OrderByProductsEnum.PROFIT_MARGIN_ASC,
                                sortDesc:
                                    OrderByProductsEnum.PROFIT_MARGIN_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                                align: 'center',
                            },
                            {
                                // I18N
                                name: 'Saldo CD',
                                sortAsc: OrderByProductsEnum.CD_STOCK_ASC,
                                sortDesc: OrderByProductsEnum.CD_STOCK_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                                align: 'center',
                            },
                            {
                                // I18N
                                name: 'Saldo Loja',
                                sortAsc: OrderByProductsEnum.STOCK_ASC,
                                sortDesc: OrderByProductsEnum.STOCK_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                                align: 'center',
                            },
                            {
                                // I18N
                                name: 'Preço',
                                sortAsc: OrderByProductsEnum.PRICE_ASC,
                                sortDesc: OrderByProductsEnum.PRICE_DESC,
                                onSortChange: (order) =>
                                    setFilter({ ...filter, order }),
                                align: 'center',
                            },
                            {},
                        ]}
                    >
                        {products.records ? (
                            products.records.map((product) => {
                                return (
                                    <tr
                                        key={product.uuid}
                                        onClick={() =>
                                            handleRowClick(
                                                getProductFormRoute(
                                                    product.uuid
                                                )
                                            )
                                        }
                                    >
                                        <td>
                                            {product?.barCodes?.find(
                                                (x) => x.main
                                            )?.barCode || '-'}
                                        </td>
                                        <td>{product.name1}</td>
                                        <td className={'text-center'}>
                                            {
                                                product.familyModel?.uuid ? (
                                                    <Link onClick={(e) => e.stopPropagation()} target={'_blank'} to={getProductFamilyFormRoute(product.familyModel.uuid)}>{product.familyModel.name}</Link>
                                                ) : '-'

                                            }
                                        </td>
                                        <td className={'text-center'}>{`${formatValue(product.avgCost)} / ${
                                            calculateMarginPercentage(product.price, product.avgCost)
                                        }`}</td>
                                        <td className={'text-center'}>{product.cdStock}</td>
                                        <td className={'text-center'}>{product.stock}</td>
                                        <td className={'text-center'}>{formatValue(product.price)}</td>
                                        <td>
                                            <div
                                                className={'actions-container'}
                                            >
                                                {product.familyModel?.name && (
                                                    <Button
                                                        className={
                                                            'transparent button-sort'
                                                        }
                                                        onClick={(e) =>
                                                            handleIconFilterProductFamily(
                                                                e,
                                                                product
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                IconProductFamily
                                                            }
                                                            alt={
                                                                'Filtrar por família'
                                                            }
                                                            title={
                                                                'Filtrar por família'
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                                <Button
                                                    className={
                                                        'transparent button-sort'
                                                    }
                                                    onClick={(e) =>
                                                        handleCopyProduct(
                                                            product.uuid,
                                                            e
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={IconCopy}
                                                        alt={'Copiar produto'}
                                                        title={'Copiar produto'}
                                                    />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </Table>
                    <Pagination
                        page={products?.page}
                        pageSize={products?.pageSize}
                        count={products?.count}
                        recordCount={products?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <ProductsFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures

                    const familyNames = complementaryFilter.productsFamily.map(
                        (family) => family.value
                    );
                    const categoryNames =
                        complementaryFilter.productsCategory.map(
                            (category) => category.value
                        );
                    const brandNames = complementaryFilter.productsBrands.map(
                        (brand) => brand.value
                    );
                    const subCategoryNames =
                        complementaryFilter.productsSubCategory.map(
                            (subCategory) => subCategory.value
                        );
                    const segmentationNames =
                        complementaryFilter.productsSegmentation.map(
                            (segmentation) => segmentation.value
                        );
                    const cfop = complementaryFilter.productsCfop.map(
                        (cfop) => cfop.value
                    );
                    const icms = complementaryFilter.productsIcms.map(
                        (icms) => icms.value
                    );
                    const taxClassification =
                        complementaryFilter.productsTaxClassification.map(
                            (brand) => brand.value
                        );
                    const taxSituation =
                        complementaryFilter.productsTaxSituation.map(
                            (taxClassification) => taxClassification.value
                        );
                    const supplier = complementaryFilter.productsSupplier.map(
                        (supplier) => supplier.value
                    );
                    const representative =
                        complementaryFilter.productsRepresentative.map(
                            (representative) => representative.value
                        );

                    setFilter({
                        ...filter,
                        brand: brandNames,
                        category: categoryNames,
                        family: familyNames,
                        subCategory: subCategoryNames,
                        segmentation: segmentationNames,
                        cfop: cfop,
                        icms: icms,
                        taxClassification: taxClassification,
                        taxSituation: taxSituation,
                        supplier: supplier,
                        representative: representative,
                    });
                }}
            />
        </>
    );
}

export function getProductsRoute() {
    //I18N
    return '/produtos/listagem';
}
