const blur = (address) => {
    if (!address) return '*****';

    const length = address.length;
    const addressWithNoNumbers = address.replace(/[0-9]/g, '');
    const newAddress = addressWithNoNumbers.split(' ').splice(0, 2).join(' ');
    const newLength = newAddress.length;
    const chars = new Array(length - newLength + 1).join('*');

    return `${newAddress} ${chars}`;
};

const anonymize = () => {
    return 'Anonimizado pelo Sistema';
};

const address = {
    blur,
    anonymize,
};

export default address;
