const blur = (cpf) => {
    if (!cpf) return;

    const replaced = cpf.replace(/\D/g, '');
    const first2Nums = replaced.substring(0, 3);
    const last2Nums = replaced.substring(9);

    return `${first2Nums}******${last2Nums}`;
};

const anonymize = () => {
    return '99999999808';
};

const cpf = {
    blur,
    anonymize,
};

export default cpf;
