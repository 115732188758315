const blur = (cnpj) => {
    if (!cnpj) return;

    const replaced = cnpj.replace(/\D/g, '');
    const firstNum = replaced.substring(0, 1);
    const last7Nums = replaced.substring(7);

    return `${firstNum}******${last7Nums}`;
};

const anonymize = () => {
    return '99999999000191';
};

const cnpj = {
    blur,
    anonymize,
};

export default cnpj;
