import { formatValue } from '../../util/utilCurrency';
import { reforco } from './_utils';
import './CashFlowSummaryScss.scss';

export default function CashFlowSummaryComponent() {
    const info = reforco;

    const calculatePrice = ({ data }) => {
        const total = data.reduce((acc, item) => (acc += item.price), 0);
        return formatValue({ value: total });
    };

    return (
        <div className={'gd'}>
            {info.map((item, index) => {
                return (
                    <div className={'info-table gd-col gd-col-4'} key={index}>
                        <div className={'title'}>
                            <p>
                                <strong>{item.type}</strong>
                            </p>
                        </div>
                        <div className={'list'}>
                            <div className={'list-title'}>
                                <p>
                                    <i>
                                        <strong>{`Itens ${item.data.length}`}</strong>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <strong>
                                            {`Total: ${calculatePrice({
                                                data: item.data,
                                            })}`}
                                        </strong>
                                    </i>
                                </p>
                            </div>
                            <div className={'list-body-content'}>
                                {item.data.map((dataItem, dataIndex) => {
                                    return (
                                        <div
                                            className={'list-body'}
                                            key={dataIndex}
                                        >
                                            <div className={'list-body-title'}>
                                                <p>{`${dataItem.title}`}</p>
                                            </div>
                                            <div className={'list-body-info'}>
                                                <span>{`Hora ${dataItem.hour}`}</span>
                                                <span>{`${formatValue({
                                                    value: dataItem.price,
                                                })}`}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
