import './OrderReception.scss';
import { useLocation, useNavigate } from 'react-router';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import React, { useState } from 'react';
import SelectSupplier, { getSelectSupplierRoute } from './SelectSupplier';
import SelectSupplierRepresentative, { getSelectSupplierRepresentativeRoute } from './SelectSupplierRepresentative';
import SelectOrderDocument, { getSelectOrderDocumentRoute } from './SelectOrderDocument';

export default function OrderReceptionRouter() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const updateFormError = (value) => {
        setFormError(state => ({
            ...state,
            ...value,
        }));
    };

    const paths = {
        [getSelectSupplierRoute()]: <SelectSupplier />,
        [getSelectSupplierRepresentativeRoute()]: <SelectSupplierRepresentative />,
        [getSelectOrderDocumentRoute()]: <SelectOrderDocument />,
    };

    if (!Object.prototype.hasOwnProperty.call(paths, pathname)) {
        return navigate(getDashboardRoute());
    }

    return React.cloneElement(paths[pathname], {
        formData,
        updateFormData,
        formError,
        updateFormError,
    });
}

export const getOrderReceptionRouterRoute = () => {
    return  '/compras/recebimento-de-mercadorias/novo/*';
};
