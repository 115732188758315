import Table from '../../../components/Table';
import { IconButtonRemove, IconButtonRemove2x, IconButtonRemoveSvg } from '../../../components/images';
import Button from '../../../components/Button';
import { formatValue } from '../../../util/formatValue';
import { maskCnpjString } from '../../../components/FieldCnpj';

export default function OrderBillingDocumentsTable({
    data,
    isLoading,
    deleteDocument,
    canSave,
}) {
    return (
        <Table
            loading={isLoading}
            columns={[
                {
                    name: 'XML Vinculado',
                    align: 'center',
                },
                {
                    name: 'Emitente',
                    align: 'center',
                },
                {
                    name: 'CNPJ',
                    align: 'center',
                },
                {
                    name: 'N° Nota',
                    align: 'center',
                },

                {
                    name: 'Vencimento',
                    align: 'center',
                },
                {
                    name: 'Natureza',
                    align: 'center',
                },
                {
                    name: 'Total',
                    align: 'center',
                },
                {
                    name: 'Ação',
                    align: 'center',
                },
            ]}
        >
            {data?.map((document, index) => (
                <tr key={index}>
                    <td className={'text-center'}>{`${document.id}`.padStart(4, '0')}</td>
                    <td className={'text-center'}>{document.emitente}</td>
                    <td className={'text-center'}>{maskCnpjString(document.cnpjEmitente)}</td>
                    <td className={'text-center'}>{document.numberDocument}</td>
                    <td className={'text-center'}>{document.dueAt}</td>
                    <td className={'text-center'}>{document.natureza}</td>
                    <td className={'text-center'}>{formatValue(document.total)}</td>
                    <td className={'text-center'}>
                        <div className={'d-flex justify-content-center'}>
                            {
                                canSave && (
                                    <Button
                                        className={'transparent'}
                                        onClick={() => deleteDocument(document.id)}
                                    >
                                        <img
                                            src={IconButtonRemoveSvg}
                                            srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                            alt={'Remover'}
                                            title={'Remover'}
                                            style={{
                                                width: '12px',
                                            }}
                                        />
                                    </Button>
                                )
                            }
                        </div>
                    </td>
                </tr>
            ))}
        </Table>
    );
}
