export const vendas = [
    {
        hora: '08:10',
        quantidadeVendida: 2,
        valorProduto: 35.00
    },
    {
        hora: '08:25',
        quantidadeVendida: 1,
        valorProduto: 45.00
    },
    {
        hora: '08:40',
        quantidadeVendida: 3,
        valorProduto: 30.00
    },
    {
        hora: '08:55',
        quantidadeVendida: 4,
        valorProduto: 25.00
    },
    {
        hora: '09:05',
        quantidadeVendida: 1,
        valorProduto: 50.00
    },
    {
        hora: '09:20',
        quantidadeVendida: 2,
        valorProduto: 40.00
    },
    {
        hora: '09:35',
        quantidadeVendida: 3,
        valorProduto: 20.00
    },
    {
        hora: '09:50',
        quantidadeVendida: 5,
        valorProduto: 15.00
    }
];

export const intervalosDeCompras = [
    {
        horaInicial: '08:00',
        horaFinal: '09:00',
        quantidadeVendida: 423,
        valorTotal: 800,
    },
    {
        horaInicial: '09:00',
        horaFinal: '10:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '10:00',
        horaFinal: '11:00',
        quantidadeVendida: 423,
        valorTotal: 800,
    },
    {
        horaInicial: '11:00',
        horaFinal: '12:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },

    {
        horaInicial: '12:00',
        horaFinal: '13:00',
        quantidadeVendida: 423,
        valorTotal: 800,
    },
    {
        horaInicial: '13:00',
        horaFinal: '14:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '14:00',
        horaFinal: '15:00',
        quantidadeVendida: 423,
        valorTotal: 800,
    },
    {
        horaInicial: '15:00',
        horaFinal: '16:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '16:00',
        horaFinal: '17:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '17:00',
        horaFinal: '18:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '18:00',
        horaFinal: '19:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
    {
        horaInicial: '19:00',
        horaFinal: '20:00',
        quantidadeVendida: 123,
        valorTotal: 4200,
    },
];


export const colaborador = [
    {
        value: 'Jorginho',
    },
    {
        value: 'Ana',
    },
    {
        value: 'Lucas',
    },
];

export const caixas = [
    {
        value: 'caixa 1',
    },
    {
        value: 'caixa 2',
    },
    {
        value: 'caixa 3',
    },
];

export const reforco = [
    {
        type: 'Reforço',
        data: [
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Reforço caixa adicional',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Entrada de caixa venda',
                hour: '17:22',
                price: 213.45,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
        ]
    },
    {
        type: 'Sangria',
        data: [
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Reforço caixa adicional',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Entrada de caixa venda',
                hour: '17:22',
                price: 213.45,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
        ]
    },
    {
        type: 'Trocas',
        data: [
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Reforço caixa adicional',
                hour: '17:22',
                price: 213,
            },
            {
                title: 'Entrada de caixa venda',
                hour: '17:22',
                price: 213.45,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
            {
                title: 'Entrada de caixa adicional de venda',
                hour: '17:22',
                price: 213.50,
            },
        ]
    }


];

export const fechamentoCaixa = {
    opcoes: [
        {
            type: 'Cielo',
            total: 430,
            credito: 120,
            debito: 60,
            pix: 250,
        },
        {
            type: 'Rede',
            credito: 300,
            debito: 50,
            pix: 500,
            total: 850
        },
        {
            type: 'Stone',
            credito: 200,
            debito: 150,
            pix: 400,
            total: 550
        },
        {
            type: 'Visa',
            credito: 200,
            debito: 150,
            pix: 400,
            total: 550
        },
        {
            type: 'PagPay',
            credito: 200,
            debito: 150,
            pix: 400,
            total: 550
        },
    ],
};

export const caixasGerais = [
    {
        caixa: 1,
        calaborador: 'Marcio Oliveira',
        valorAbertura: 3909.77,
        vendas: 4909.77,
        reforco: 1200,
        trocas: 900,
        sangria: 290,
        cartoes: 1111.79,
        dinheiro: 1111.79,
        nf: 50,
        diferenca: 250,
    },
    {
        caixa: 2,
        calaborador: 'Maria Lucia',
        valorAbertura: 3909.77,
        vendas: 4909.77,
        reforco: 1200,
        trocas: 900,
        sangria: 290,
        cartoes: 1111.79,
        dinheiro: 1111.79,
        nf: 50,
        diferenca: 250,
    },
    {
        caixa: 3,
        calaborador: 'Ricardo Oliveira',
        valorAbertura: 3909.77,
        vendas: 4909.77,
        reforco: 1200,
        trocas: 900,
        sangria: 290,
        cartoes: 1111.79,
        dinheiro: 1111.79,
        nf: 50,
        diferenca: 250,
    },
    {
        caixa: 4,
        calaborador: 'Cláudia Dias',
        valorAbertura: 3909.77,
        vendas: 4909.77,
        reforco: 1200,
        trocas: 900,
        sangria: 290,
        cartoes: 1111.79,
        dinheiro: 1111.79,
        nf: 50,
        diferenca: 250,
    }
];

