import Table from '../../../components/Table';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as OrderReceptionService from '../../../services/OrderReceptionService';
import Pagination from '../../../components/Pagination';
import { PermissionsEnum } from 'erva-doce-common';
import ButtonGoto from '../../../components/ButtonGoto';
import { useNavigate } from 'react-router';
import { getOrderDetailReceiveRoute } from '../order-receive/OrderDetailReceive';

const FILTERS = {
    order: null,
    page: 0,
    orderId: null,
};

export default function ReceiveList({ order }) {
    const navigate = useNavigate();
    const { backendConnectionError, user } = useContext(EnvironmentContext);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(FILTERS);

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data = await OrderReceptionService.getAllByOrder(filter);

            setData(data);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch Receives', err, null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!filter.orderId) return;
        getItems();
    }, [filter]);

    useEffect(() => {
        setFilter({ ...filter, orderId: order.id });
    }, [order]);

    return (
        <>
            <Table
                loading={isLoading}
                columns={[
                    {
                        name: 'N° Recebimento',
                    },
                    {
                        name: 'Responsável',
                        align: 'center',
                    },
                    {
                        name: 'Qtd itens',
                        align: 'center',
                    },
                    {
                        name: 'Data',
                        align: 'center',
                    },
                    {
                        name: 'Finalizado em',
                        align: 'center',
                    },
                    {
                        name: '',
                        align: 'end',
                        width: '50%',
                    },
                ]}
            >
                {data?.records?.length ? data?.records?.map((receive, index) => (
                    <tr key={index}>
                        <td>{`${receive.id}`.padStart(7, '0')}</td>
                        <td className={'text-center'}>{receive.responsibleName}</td>
                        <td className={'text-center'}>{receive.count}</td>
                        <td className={'text-center'}>{receive.createdAt}</td>
                        <td className={'text-center'}>{receive.finishedAt || '-'}</td>
                        <td>
                            {
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_RECEIVE_ORDER)) && (
                                    <div className={'d-flex justify-content-end'}>
                                        <ButtonGoto route={getOrderDetailReceiveRoute(order.id, receive.uuid)} />
                                    </div>
                                )
                            }
                        </td>
                    </tr>
                )) : <tr><td colSpan={8} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
                {
                    !!data?.records?.length && (
                        <tr className={'total-row'}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )
                }
            </Table>
            <Pagination
                page={data?.page}
                pageSize={data?.pageSize}
                count={data?.count}
                recordCount={data?.records?.length || 0}
                onPageChange={(page) => setFilter({ ...filter, page })}
            />
        </>
    );
}
