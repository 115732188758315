import { useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldNumber from '../../components/FieldNumber';

const INITIAL_STATE = {
    count: 0,
};

export default function ConfirmProductFoundModal({
    show,
    onCancel,
    onConfirm,
    productFound,
    filter,
    receive,
}) {
    const countRef = useRef();

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.count || formData.count <= 0) {
            hasError = true;
            setFormError({ count: 'Preencha o campo corretamente' });
        }

        return hasError;
    };

    const confirm = () => {
        if (hasValidationErrors()) return;

        onConfirm(formData);
    };

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [show]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            className={'stock-check-form'}
            title={'Confirmar recebimento de produto'}
        >
            <p className={'mb-24'}>{'Volume selecionado: '}{`${filter.volume}`.padStart(2, 0)}{' de '}{`${receive.totalVolumes || 1}`.padStart(2, 0)}</p>
            {
                productFound && (
                    <>
                        <div className={'product-info mb-14'} style={{ backgroundColor: '#E8E8E8' }}>
                            <table>
                                <thead className={'mb-24'}>
                                    <tr>
                                        <th>{'Código barras'}</th>
                                        <th>{'Nome do produto'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{productFound?.barCodes?.find(x => x.main)?.barCode || '-'}</td>
                                        <td>{[productFound.name1,productFound.name2,productFound.name3].join(' ')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'col-12 col-md-12 mb-14'}>
                            <FieldNumber
                                ref={countRef}
                                label={'Quantidade recebida:'}
                                fieldGroup={true}
                                thousandsSeparator={false}
                                decimalLimit={0}
                                value={formData?.count}
                                onChange={({ target }) => {
                                    updateFormData({ count: Number(target.value) });
                                }}
                                validationError={formError?.count}
                            />
                        </div>
                    </>
                )
            }
        </SimpleConfirmModal>
    );
}
