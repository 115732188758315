import api from '../util/api';

export async function getAllCoupons({ filter }) {
    try {
        const res = (await api.get('/coupon?page=0')).data;
        res.records = res.records.map((coupon) => {
            return {
                id: coupon.uuid,
                code: coupon.code,
                dtCriação: new Date(coupon.createdAt).toLocaleDateString(),
                isActive: coupon.isActive,
                freeShipping: coupon.freeShipping,
                typeFixed: coupon.type === 'percent' ? false : true,
                typePercent: coupon.type === 'percent' ? true : false,
                value: coupon.value,
                minValue: coupon.minValue,
            };
        });
        return res;
    } catch (error) {
        return {};
    }
}


export async function getCouponByUUID({ uuid }) {
    try {
        const res = (await api.get(`/coupon/${uuid}`)).data;
        return {
            id: res.id,
            uuid: res.uuid,
            code: res.code,
            isActive: res.isActive,
            freeShipping: res.freeShipping,
            type: res.type,
            typeFixed: res.type === 'percent' ? false : true,
            typePercent: res.type === 'percent' ? true : false,
            value: res.value,
            minValue: res.minValue,
            description: res.description,
            userList: res.users,
            isOnlyEcommerce: res.isOnlyEcommerce,
        };
    } catch (error) {
    }
}

export async function createANewCoupon({ formData }) {
    try {
        const res = (await api.post('/coupon', formData));
    } catch (error) {

    }
}

export async function updateCoupon({ formData, uuid }) {
    try {
        const res = (await api.put(`/coupon/${uuid}`, formData));
    } catch (error) {

    }
}

export async function getCustomerByCpf({ cpf }) {
    try {
        const res = (await api.get(`/customers/${cpf}`)).data;
        return res;
    } catch (error) {

    }
}

export async function validateCoupon(code, customerId) {
    try {
        const res = (await api.get(`/coupon/${code}/validate/${customerId}`)).data;
        return res;
    } catch (error) {

    }
}
