import { useState } from 'react';
import FieldCollaborator from '../../components/FieldCollaborator';
import FieldCpf from '../../components/FieldCpf';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import {isValidCpf} from 'erva-doce-common';

function SalesModal({
    show,
    onCancel,
    onConfirm,
    onChangeForm,
    isSaleEditable,
}) {
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState();
    const [, setValidateOnChange] = useState(false);

    function updateFormData(data) {
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, ...data };
            return updatedFormData;
        });
    }

    function handleInputChange(data) {
        updateFormData(data);
        onChangeForm({ ...formData, ...data });
    }

    function confirm() {
        let errors = hasValidationError();
        if(!errors) {
            onConfirm({
                formData,
            });
        }
    }

    function cancel() {
        setFormData({});
        onChangeForm({});
        onCancel();
    }

    function hasValidationError() {
        let hasError = false;
        setFormError({});
        setValidateOnChange(true);

        if (formData.customerCpf) {
            if(!isValidCpf(formData.customerCpf)) {
                hasError = true;
                setFormError((formError) => ({ ...formError, customerCpf: 'Digite um cpf válido.' }));
            }
        }
        return hasError;
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={'Nova Venda'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}
                    style={{display: isSaleEditable ? 'none' : ''}}
                >
                    <FieldCollaborator
                        label={'Vendedor'}
                        disabled={isSaleEditable}
                        multipleSelection={false}
                        onSelected={(target) => {
                            if(target) {
                                handleInputChange({ seller: target });
                            }
                        }}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <FieldCpf
                        label={'CPF'}
                        placeholder={'Informe o CPF do cliente para cadastro'}
                        value={formData.customerCpf}
                        onChange={({ target }) => {
                            handleInputChange({ customerCpf: target.value, cpfNote: target.value });
                        }}
                        validationError={formError?.customerCpf}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default SalesModal;
