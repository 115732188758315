import {
    PermissionsEnum,
    ValidationErrorQuotationTypeText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as QuotationsTypesService from '../../services/QuotationsTypesService';
import { getQuotationsTypesRoute } from './QuotationsTypes';

export default function QuotationTypeForm() {
    const emptyFormData = {
        name: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingQuotationType, setLoadingQuotationType] = useState(!!uuid);
    const [quotationTypeName, setQuotationTypeName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchQuotationType() {
        try {
            setLoadingQuotationType(true);
            const quotationType = await QuotationsTypesService.getQuotationType(uuid);
            setQuotationTypeName(quotationType.name);
            setFormData({
                ...quotationType
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Tipo de cotação não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch quotationType', e, null, title);
            }
        } finally {
            setLoadingQuotationType(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchQuotationType();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do tipo de cotação' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            f3Hotkey = addHotkey('F3', () => {
                buttonDeleteRef.current?.click();
            });
        }
        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    function back() {
        navigate(getQuotationsTypesRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim()
            };

            let message;
            if (!uuid) { // new collaborator
                await QuotationsTypesService.addQuotationType(body);
                // I18N
                message = 'Tipo de cotação salvo com sucesso!';
            } else {
                // I18N
                await QuotationsTypesService.editQuotationType(uuid, body);
                message = 'Tipo de cotação editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit quotationType', e, null, title, ValidationErrorQuotationTypeText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteQuotationType() {
        // I18N
        let title = 'Excluir tipo de cotação';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await QuotationsTypesService.deleteQuotationType(uuid);
                // I18N
                const message = 'Tipo de cotação excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete quotationType', e, null, title, ValidationErrorQuotationTypeText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o tipo de cotação?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar tipo de cotação';
            if (windowTitle && quotationTypeName) title += ` - ${quotationTypeName}`;
            return title;
        } else {
            return 'Novo tipo de cotação';
        }
    }

    const renderForm = () => {
        if (loadingQuotationType) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-8'} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteQuotationType}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Tipos de cotações', route: getQuotationsTypesRoute() },
                        { name: uuid ? (loadingQuotationType ? '...' : quotationTypeName) : title },
                    ]}
                    backRoute={getQuotationsTypesRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getQuotationTypeFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/produtos/tipos-de-cotacoes/${uuid}`;
    } else {
        return '/produtos/tipos-de-cotacoes/novo';
    }
}
