import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';

function StoresFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    function confirm() {
        onConfirm({});
    }

    function cancel() {
        onCancel();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar lojas'}
        >
            <div className={'gd'}>
            </div>
        </SimpleConfirmModal>
    );
}

export default StoresFilterModal;
