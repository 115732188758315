import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByRepresentativesEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonBadge from '../../components/ButtonBadge';
import { maskPhoneString } from '../../components/FieldPhone';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as RepresentativesService from '../../services/RepresentativesService';
import { getRepresentativeFormRoute } from './RepresentativeForm';
import RepresentativesFilterModal from './RepresentativesFilterModal';

export default function Representatives() {
    const [representatives, setRepresentatives] = useState(null);
    const [representativesLoading, setRepresentativesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByRepresentativesEnum.NAME_ASC,
        search: null,
        segmentations: [],
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [showFilter, setShowFilter] = useState(false);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchRepresentatives();
    }, [filter]);

    useEffect(() => {
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
        };
    }, []);

    async function fetchRepresentatives() {
        if (representativesLoading?.cancel) {
            representativesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setRepresentativesLoading(cancelToken);
            const response = await RepresentativesService.getRepresentatives(
                filter.page,
                filter.search,
                filter.segmentations?.map(segmentation => segmentation.id),
                filter.order,
                cancelToken);
            setRepresentatives(response);
            setRepresentativesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request canceled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setRepresentativesLoading(e);
                setRepresentatives(null);
            }
        }
    }

    function renderTable() {
        if (representativesLoading && representativesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os representantes'}
                    onTryAgain={fetchRepresentatives}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = representatives?.records ?? [];
        for (const representative of records) {
            rows.push(
                <tr key={representative.uuid} onClick={() => handleRowClick(getRepresentativeFormRoute(representative.uuid))}>
                    <td>
                        {representative.name}
                    </td>
                    <td>
                        {representative.email}
                    </td>
                    <td>
                        {maskPhoneString(representative.phone)}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={representativesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Representante',
                            sortAsc: OrderByRepresentativesEnum.NAME_ASC,
                            sortDesc: OrderByRepresentativesEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'E-mail',
                            sortAsc: OrderByRepresentativesEnum.EMAIL_ASC,
                            sortDesc: OrderByRepresentativesEnum.EMAIL_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Contato',
                            sortAsc: OrderByRepresentativesEnum.PHONE_ASC,
                            sortDesc: OrderByRepresentativesEnum.PHONE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={representatives?.page}
                    pageSize={representatives?.pageSize}
                    count={representatives?.count}
                    recordCount={representatives?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    const filterBadgeCount = (filter?.segmentations?.length ?? 0);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Representantes'}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Representantes', route: getRepresentativesRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canAdd ? 'gd-col-6' : 'gd-col-9'}`}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={'<em>Buscar por <strong>representante</strong> [F2]</em>'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getRepresentativeFormRoute()}
                                >
                                    {'Novo representante'}
                                </Button>
                            </div>
                        )}
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                badgeChildren={!!filterBadgeCount && <ButtonBadge count={filterBadgeCount} />}
                                onClick={() => setShowFilter(true)}
                            >
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <RepresentativesFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>

    );
}

export function getRepresentativesRoute() {
    //I18N
    return '/administrativo/representantes';
}
