import './SearchExchangeModal.scss';
import * as ExchangesService from '../services/ExchangesService';
import * as Sentry from '@sentry/react';
import SimpleConfirmModal from './modal/SimpleConfirmModal';
import { useEffect, useRef, useState } from 'react';
import Table from './Table';
import axios from 'axios';
import FieldCpfSearch from './FieldCpfSearch';
import { unmaskCpf }  from '../components/FieldCpf';
import { formatCpfMask } from 'erva-doce-common';

function SearchExchangeModal({
    show,
    onCancel,
    onSelect,
    searchValue,
}) {
    const emptyFormData = {
        exchanges: []
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const inputRef = useRef();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
    }

    function cancel() {
        onCancel();
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [show]);


    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchExchangesByCustomerCpf();
    }, [searchValue]);

    async function fetchExchangesByCustomerCpf() {
        try {
            if (searchValue) {
                const exchanges = await ExchangesService.getExchangesByCustomerCpf(unmaskCpf(searchValue));

                updateFormData([]);
                updateFormData({ exchanges:  exchanges });
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    }

    function selectExchange(prod){
        onSelect && onSelect(prod);
    }

    const checkKey = (event) => {
        event = event || window.event;

        if (event.key === 'ArrowUp') {
            let newSelectionIdx = selectedIdx - 1;
            if (newSelectionIdx < 0) newSelectionIdx = 0;
        } else if (event.key === 'ArrowDown') {
            let newSelectionIdx = selectedIdx + 1;
            if (newSelectionIdx >= formData.exchanges.length) newSelectionIdx = formData.exchanges.length - 1;
            setSelectedIdx(newSelectionIdx);
        }
    };

    document.onkeydown = checkKey;

    function renderTable() {
        // if (loading && loading instanceof Error) {
        //     return (
        //         <LoadError
        //             // I18N
        //             message={'Não foi possível carregar os itens'}
        //             // onTryAgain={fetchExchanges}
        //         />
        //     );
        // }

        let rows = [];
        // noinspection JSUnresolvedReference
        for (const [index, p] of formData.exchanges.entries()) {
            const selectedRow = index === selectedIdx;
            rows.push(
                <tr key={p.uuid} onClick={() => selectExchange(p)} className={selectedRow ? 'selected' : null}>

                    <td>
                        {p.customerName}
                    </td>
                    <td>
                        {formatCpfMask(p.customerCpf)}
                    </td>
                    <td>
                        {p.total?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}
                    </td>

                </tr>
            );
        }

        return (
            <Table
                // loading={loading}
                columns={[
                    {
                        // I18N
                        name: 'Nome do cliente'
                    },
                    {
                        name: 'CPF do cliente'
                    },
                    {
                        // I18N
                        name: 'Total'
                    },
                ]}>
                {rows}
            </Table>
        );
    }

    return (

        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={() => selectExchange(formData.exchanges[selectedIdx])}
            // I18N
            title={'Buscar devolução'}
            className={'search-exchange-modal'}
            confirmText={'Selecionar'}
        >
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <FieldCpfSearch
                            placeholder={'Digite o cpf do cliente'}
                            value={formatCpfMask(searchValue)}
                            onDisabled={true}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        {renderTable()}
                    </div>
                </div>
            </fieldset>
            {selectedIdx != -1 && (
                <div className={'selected-exchange'}>
                    {formData.exchanges[selectedIdx].name1 + ' ' + formData.exchanges[selectedIdx].name2 + ' ' + formData.exchanges[selectedIdx].name3}
                    <span>{formData.exchanges[selectedIdx].price?.(undefined, { style: 'currency', currency: 'BRL', })}</span>
                </div>

            )}
        </SimpleConfirmModal>
    );
}

export default SearchExchangeModal;
