import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getSelectSupplierRepresentativeRoute } from './SelectSupplierRepresentative';
import FieldText from '../../components/FieldText';
import { useEffect, useRef } from 'react';
import FieldCnpj, { unmaskCnpj } from '../../components/FieldCnpj';
import * as OrderReceptionService from '../../services/OrderReceptionService';
import { getOrderReceiveRoute } from '.';

export default function SelectSupplier({ formData, formError, updateFormData, updateFormError }) {
    const title = 'Busque pelo fornecedor';

    const navigate = useNavigate();
    const socialReasonRef = useRef();

    const nextPage = async () => {
        try {
            const res = await OrderReceptionService.searchSuppliers({ cnpj: formData.cnpj, socialReason: formData.socialReason });

            if (res) {
                navigate(getSelectSupplierRepresentativeRoute());
            }
        } catch(err) {
            console.log(err);

            if (err?.response?.status === 404) {
                if (formData.cnpj) {
                    updateFormError({ cnpj: 'Número de CNPJ não encontrado' });
                }

                if (formData.socialReason) {
                    updateFormError({ socialReason: 'Razão social não encontrada' });
                }
            }
        }
    };

    const updateForm = (value) => {
        const key = Object.keys(value)[0];

        updateFormError({ [key]: null });
        updateFormData(value);
    };

    useEffect(() => {
        updateFormData({ billings: [], supplierRepresentative: null });
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: 'Recebimento de mercadorias', route: getOrderReceiveRoute() },
                        { name: 'Novo' },
                        { name: title, route: getSelectSupplierRoute() },
                    ]}
                    hideStore
                />
                <div className={'col-12 col-6 mb-14'}>
                    <FieldCnpj
                        label={'CNPJ'}
                        value={formData?.cnpj}
                        onChange={(e) => updateForm({ cnpj: unmaskCnpj(e.target.value) })}
                        placeholder={'Buscar por CNPJ'}
                        validationError={formError?.cnpj}
                    />
                </div>
                <div className={'col-12 col-6 mb-14'}>
                    <FieldText
                        ref={socialReasonRef}
                        label={'Razão Social'}
                        value={formData?.socialReason || ''}
                        onChange={(e) => updateForm({ socialReason: e.target.value })}
                        validationError={formError?.socialReason}
                        placeholder={'Buscar por razão social'}
                    />
                </div>
                <div className={'col-12 col-md-3 mb-10'}>
                    <Button
                        className={'w-100'}
                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        onClick={nextPage}
                        disabled={!formData?.cnpj && !formData?.socialReason}
                    >
                        {'Buscar fornecedores'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export function getSelectSupplierRoute() {
    return '/compras/recebimento-de-mercadorias/novo';
}
