import { useState } from 'react';
import ButtonGoto from '../../components/ButtonGoto';
import ButtonRemove from '../../components/ButtonRemove';
import FieldCurrency, { formatCurrency } from '../../components/FieldCurrency';
import { maskPhoneString } from '../../components/FieldPhone';
import FieldQuotationType from '../../components/FieldQuotationType';
import FieldSupplier from '../../components/FieldSupplier';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import Table from '../../components/Table';
import { getSupplierFormRoute } from '../suppliers/SupplierForm';
import FieldCollaborator from '../../components/FieldCollaborator';

function SupplierRow({
    value,
    onRemove,
    onChange,
    readOnly,
}) {
    const [supplier, setSupplier] = useState(value);

    function updateSupplier(supplier) {
        setSupplier(supplier);
        if (onChange) onChange(supplier);
    }

    return (
        <tr>
            <td className={'center'}>
                <Picture
                    picture={supplier.picture}
                    name={supplier.fantasyName}
                    size={PictureSize.NORMAL}
                    style={PictureStyle.STORE_SQUARE}
                />
            </td>
            <td>{supplier.fantasyName}</td>
            <td>{supplier.email ?? ''}</td>
            <td>{maskPhoneString(supplier.phone) ?? ''}</td>
            <td>
                <FieldQuotationType
                    label={null}
                    fieldGroup={false}
                    select={supplier.quotationType}
                    onSelected={(quotationType) =>
                        updateSupplier({ ...supplier, quotationType })
                    }
                    readOnly={readOnly}
                />
            </td>
            <td className={'right'}>
                <FieldCurrency
                    fieldGroup={false}
                    value={supplier.minimumOrder}
                    onChange={({ target }) =>
                        updateSupplier({
                            ...supplier,
                            minimumOrder: formatCurrency(target.value),
                        })
                    }
                    readOnly={readOnly}
                />
            </td>
            <td>
                <FieldCollaborator
                    fieldGroup={false}
                    multipleSelection={false}
                    onSelected={(responsible) => {
                        updateSupplier({ ...supplier, responsible });
                    }}
                    select={supplier.responsible}
                    readOnly={readOnly}
                />
            </td>
            <td className={'controls'}>
                {!readOnly && (
                    <>
                        <ButtonRemove onClick={onRemove} />
                        <ButtonGoto
                            onClick={() => {
                                window.open(
                                    getSupplierFormRoute(supplier.uuid),
                                    '',
                                    ''
                                );
                            }}
                        />
                    </>
                )}
            </td>
        </tr>
    );
}

function Suppliers({
    value = [],
    onChange,
    readOnly,
    ...props
}) {
    const [suppliers, setSuppliers] = useState(value);

    function updateSuppliers(suppliers) {
        setSuppliers(suppliers);
        if (onChange) onChange(suppliers);
    }

    function renderTable() {
        const rows = [];
        suppliers.forEach((supplier, index) => {
            rows.push((
                <SupplierRow
                    key={index}
                    value={supplier}
                    onRemove={() => updateSuppliers(suppliers.filter((s) => s.uuid !== supplier.uuid))}
                    onChange={(data) => {
                        suppliers[index] = data;
                        updateSuppliers(suppliers);
                    }}
                    readOnly={readOnly}
                />
            ));
        });
        return rows;
    }

    function addSupplier(supplier) {
        updateSuppliers([...suppliers, {
            uuid: supplier.id,
            fantasyName: supplier.value,
            email: supplier.email,
            phone: supplier.phone,
            picture: supplier.picture,
        }]);
    }

    return (
        <>
            {!readOnly && (
                <div className={'gd'}>
                    <div className={'gd-col-6'}>
                        <FieldSupplier
                            multipleSelection={true}
                            onAddItem={(supplier) => addSupplier(supplier)}
                            ignoreItems={suppliers.map(
                                (supplier) => supplier.uuid
                            )}
                        />
                    </div>
                </div>
            )}
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <Table
                        columns={[
                            {}, // picture
                            {
                                // I18N
                                name: 'Fornecedor',
                            },
                            {
                                // I18N
                                name: 'E-mail',
                            },
                            {
                                // I18N
                                name: 'Telefone',
                            },
                            {
                                // I18N
                                name: 'Cotação',
                            },
                            {
                                // I18N
                                name: 'Pedido mínimo',
                            },
                            {
                                // I18N
                                name: 'Responsável',
                            },
                            {}, // controls
                        ]}
                    >
                        {renderTable()}
                    </Table>
                </div>
            </div>
        </>
    );
}

export default Suppliers;
