import api from '../util/api';

export async function get(filters) {
    return (await api.get('/campaigns', {
        params: filters,
    })).data;
}

export async function getByUUID(uuid) {
    return (await api.get(`/campaigns/${uuid}`)).data;
}

export async function create(formData) {
    return await api.post('/campaigns', formData);
}

export async function update(uuid, formData) {
    return await api.put(`/campaigns/${uuid}`, formData);
}

export async function destory(uuid) {
    return await api.delete(`/campaigns/${uuid}`);
}

