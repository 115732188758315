import Table from '../../../components/Table';
import { IconButtonRemove, IconButtonRemove2x, IconButtonRemoveSvg } from '../../../components/images';
import Button from '../../../components/Button';
import FieldDate from '../../../components/FieldDate';
import FieldText from '../../../components/FieldText';
import { BillingTypeEnum, BillingTypeText } from 'erva-doce-common';
import FieldCurrency, { formatCurrency } from '../../../components/FieldCurrency';

export default function OrderBillingBillsTable({
    data,
    isLoading,
    deleteItem,
    updateItem,
    canSave,
}) {
    return (
        <Table
            loading={isLoading}
            columns={[
                {
                    name: 'Vencimento',
                },
                {
                    name: 'Tipo do boleto',
                    width: '20%',
                },
                {
                    name: 'Valor',
                    align: 'center',
                    width: '10%',
                },

                {
                    name: 'Numeração',
                    align: 'center',
                    width: '10%',
                },
                {
                    name: 'Ação',
                    align: 'end',
                    width: '50%',
                }
            ]}
        >
            {data.length ? data?.map((billing, index) => (
                <tr key={index}>
                    <td className={'count-selected text-center'}>
                        <FieldDate
                            fieldGroup={false}
                            readOnly={billing.type === BillingTypeEnum.BILLING}
                            onChange={({ target }) =>
                                updateItem(index, {
                                    billingDate: target.value,
                                })
                            }
                            value={billing.billingDate || ''}
                        />
                    </td>
                    <td className={'count-selected text-center'}>
                        <FieldText
                            readOnly
                            textAlign={'left'}
                            fieldGroup={false}
                            required={false}
                            value={billing.type ? BillingTypeText(billing.type) : '-'}
                        />
                    </td>
                    <td className={'count-selected text-center'}>
                        <FieldCurrency
                            type={'text'}
                            onChange={({ target }) => updateItem(index, { total: formatCurrency(target.value) })}
                            value={billing?.total || ''}
                            disabled={billing.type === BillingTypeEnum.BILLING}
                            fieldGroup={false}
                            required
                        />
                    </td>
                    <td className={'count-selected text-center'}>
                        <FieldText
                            fieldGroup={false}
                            required={true}
                            value={billing.number}
                            onChange={({ target }) => updateItem(index, {
                                number: target.value,
                            })}
                            readOnly={billing.type === BillingTypeEnum.BILLING}
                        />
                    </td>
                    <td className={'text-end'}>
                        {
                            billing.type !== BillingTypeEnum.BILLING && canSave ? (
                                <div className={'d-flex justify-content-end'}>
                                    <Button
                                        className={'transparent'}
                                        onClick={() => deleteItem(index)}
                                    >
                                        <img
                                            src={IconButtonRemoveSvg}
                                            srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                            alt={'Remover'}
                                            title={'Remover'}
                                            style={{
                                                width: '12px',
                                            }}
                                        />
                                    </Button>
                                </div>
                            ) : '-'
                        }
                    </td>
                </tr>
            )) : <tr><td colSpan={17} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
        </Table>
    );
}
