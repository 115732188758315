import { useNavigate } from 'react-router';
import { getSelectSupplierRoute } from './SelectSupplier';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { getSelectOrderDocumentRoute } from './SelectOrderDocument';
import { useEffect, useState } from 'react';
import * as OrderReceptionService from '../../services/OrderReceptionService';
import { maskCnpjString } from '../../components/FieldCnpj';
import { getOrderReceiveRoute } from '.';

export default function SelectSupplierRepresentative({ formData, updateFormData }) {
    const title = 'Listagem de fornecedores';
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    if (!formData.cnpj && !formData.socialReason) {
        return navigate(getSelectSupplierRoute());
    }

    const nextPage = () => {
        navigate(getSelectOrderDocumentRoute());
    };

    const getInfo = async () => {
        updateFormData({ billings: [] });

        try {
            const res = await OrderReceptionService.searchSuppliers({ cnpj: formData.cnpj, socialReason: formData.socialReason });
            if (res) {
                setData(res);
            }
        } catch(err) {
            console.log(err);
            navigate(getSelectSupplierRoute());
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Pedidos' },
                        { name: 'Recebimento de mercadorias', route: getOrderReceiveRoute() },
                        { name: 'Novo' },
                        { name: title, route: getSelectSupplierRoute() },
                    ]}
                    backRoute={getSelectSupplierRoute()}
                    hideStore
                />
                <div className={'mb-20'}>
                    {
                        data?.map(supplier => (
                            <div key={supplier.id} className={'supplier-representative'}>
                                <div>
                                    <input
                                        name={'supplierRepresentative'}
                                        id={supplier.id}
                                        type={'radio'}
                                        value={supplier.id}
                                        checked={formData.supplierRepresentative === supplier.id}
                                        onChange={() => {
                                            updateFormData({ ...formData, supplierRepresentative: supplier.id });
                                        }}
                                    />
                                </div>
                                <label htmlFor={supplier.id} className={'supplier-representative--info'}>
                                    <div>
                                        <p className={'supplier-representative--socialReason'}><strong>{supplier.socialReason}</strong></p>
                                        <p className={'supplier-representative--cnpj'}>{maskCnpjString(supplier.cnpj)}</p>
                                    </div>
                                    <p>{supplier.representativeName}{', '}{supplier.representativePhone}</p>
                                </label>
                            </div>
                        ))
                    }
                </div>
                <div className={'col-12 col-md-3 mb-10'}>
                    <Button
                        className={'w-100'}
                        buttonStyle={ButtonStyle.BUTTON_NORMAL}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        onClick={nextPage}
                        disabled={!formData?.supplierRepresentative}
                    >
                        {'Buscar notas fiscais'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export function getSelectSupplierRepresentativeRoute() {
    return '/compras/recebimento-de-mercadorias/novo/fornecedores';
}
