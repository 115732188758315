import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByStoresEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonBadge from '../../components/ButtonBadge';
import { maskCnpjString } from '../../components/FieldCnpj';
import { maskPhoneString } from '../../components/FieldPhone';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as StoresService from '../../services/StoresService';
import { getStoresFormRoute } from './StoreForm';
import StoresFilterModal from './StoresFilterModal';

export default function Stores() {
    const [stores, setStores] = useState(null);
    const [storesLoading, setStoresLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByStoresEnum.SOCIAL_REASON_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin;
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchStores();
    }, [filter]);

    useEffect(() => {
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
        };
    }, []);

    async function fetchStores() {
        if (storesLoading?.cancel) {
            storesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setStoresLoading(cancelToken);
            const response = await StoresService.getStores(
                filter.page,
                filter.search,
                filter.order,
                cancelToken);
            setStores(response);
            setStoresLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request canceled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setStoresLoading(e);
                setStores(null);
            }
        }
    }

    function renderTable() {
        if (storesLoading && storesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar as lojas'}
                    onTryAgain={fetchStores}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = stores?.records ?? [];
        for (const store of records) {
            rows.push(
                <tr key={store.uuid} onClick={() => handleRowClick(getStoresFormRoute(store.uuid))}>
                    <td>
                    </td>
                    <td>
                        {store.socialReason}
                    </td>
                    <td>
                        {store.fantasyName}
                    </td>
                    <td>
                        {maskCnpjString(store.cnpj)}
                    </td>
                    <td>
                        {store.phone ? maskPhoneString(store.phone) : '-'}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={storesLoading}
                    currentSort={filter.order}
                    columns={[
                        {}, // picture column
                        {
                            // I18N
                            name: 'Razão social',
                            sortAsc: OrderByStoresEnum.SOCIAL_REASON_ASC,
                            sortDesc: OrderByStoresEnum.SOCIAL_REASON_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Nome fantasia',
                            sortAsc: OrderByStoresEnum.FANTASY_NAME_ASC,
                            sortDesc: OrderByStoresEnum.FANTASY_NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'CNPJ',
                            sortAsc: OrderByStoresEnum.CNPJ_ASC,
                            sortDesc: OrderByStoresEnum.CNPJ_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Telefone',
                            sortAsc: OrderByStoresEnum.PHONE_ASC,
                            sortDesc: OrderByStoresEnum.PHONE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={stores?.page}
                    pageSize={stores?.pageSize}
                    count={stores?.count}
                    recordCount={stores?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    const filterBadgeCount = (filter?.storesTypes?.length ?? 0);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Lojas'}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Lojas', route: getStoresRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canAdd ? 'gd-col-6' : 'gd-col-9'}`}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={'<em>Buscar por <strong>loja</strong> ou <strong>CNPJ</strong> [F2]</em>'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getStoresFormRoute()}
                                >
                                    {'Nova loja'}
                                </Button>
                            </div>
                        )}
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                badgeChildren={!!filterBadgeCount && <ButtonBadge count={filterBadgeCount} />}
                                onClick={() => setShowFilter(true)}
                            >
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <StoresFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>

    );
}

export function getStoresRoute() {
    //I18N
    return '/administrativo/lojas';
}
