import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import PaymentList from '../../components/PaymentList';
import Button, { ButtonColor } from '../../components/Button';
import { useEffect, useMemo, useState } from 'react';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldCpf from '../../components/FieldCpf';
import { isValidCpf, unmaskCpf } from 'erva-doce-common';
import FieldText from '../../components/FieldText';
import * as CouponService from '../../services/CouponService';

function SalesPaymentModal({
    show,
    onCancel,
    onConfirm,
    formData,
    setFormData,
    salesForm,
    setSalesForm,
    amount,
    exchangeTotal,
    updateFormData,
    addRowPayment,
    removeRowPayment,
    currencyRefs,
    couponTotal,
    setCouponTotal,
    setCouponData,
    ...props
}) {
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [balanceToPay, setBalanceToPay] = useState(0);
    const [formError, setFormError] = useState('');
    const [couponCode, setCouponCode] = useState('');

    function roundToTwo(num) {
        return Math.round(num * 100) / 100;
    }

    function sanitizeNegativeZero(num) {
        return Object.is(num, -0) ? 0 : num;
    }

    function hasValidationError() {
        let hasError = false;
        setFormError('');

        if (salesForm?.cpfNote?.trim() && !isValidCpf(salesForm.cpfNote?.trim())) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, cpfNote: 'Digite um cpf válido' }));
        }

        return hasError;
    }

    const disabled = useMemo(() => {

        return formData.payments.some(payment => {

            const numericPurchaseTotal = typeof purchaseTotal === 'string'
                ? extractCurrencyNumber(purchaseTotal)
                : purchaseTotal;
            const paymentsValues = formData.payments.map(payment => {
                return extractCurrencyNumber(payment.total);
            });

            return (
                !payment.total ||
                !payment.paymentType ||
                (payment.paymentType?.value !== 'Dinheiro' && !payment.machine) ||
                payment.quantityInstallments === 0 ||
                !payment.amountInstallments ||
                numericPurchaseTotal !== roundToTwo(amount - exchangeTotal - couponTotal) ||
                paymentsValues.some(value => value === 0) ||
                (formData?.cpfNote && unmaskCpf(formData?.cpfNote)?.length !== 11)
            );
        });
    }, [formData, salesForm?.cpfNote, formData?.payments, balanceToPay, amount, purchaseTotal]);

    const fetchCoupon = async () => {
        if (formData.couponCode && formData.customerId) {
            const coupon = await CouponService.validateCoupon(formData.couponCode, formData.customerId);

            if (coupon) {
                setFormError(state => ({ ...state, coupon: null }));

                if ((amount - exchangeTotal) <= coupon.minValue) {
                    setFormError(state => ({ ...state, coupon: 'Cupom inválido' }));
                    setCouponData(null);
                } else {
                    setCouponData(coupon);
                }
            } else {
                setFormError(state => ({ ...state, coupon: 'Cupom inválido' }));
                setCouponData(null);
                setCouponTotal(0);
            }
        } else {
            setFormError(state => ({ ...state, coupon: null }));
            setCouponData(null);
            setCouponTotal(0);
        }
    };

    useEffect(() => {
        const err = hasValidationError();
        if(!err) updateFormData({ cpfNote: salesForm?.cpfNote });
    }, [salesForm]);

    useEffect(() => {

        if (!formData?.cpfNote && formData.payments.paymentType?.value === 'Dinheiro') {
            formData.printedNote = false;
        } else if (formData.payments.paymentType?.value !== 'Dinheiro') {
            formData.printedNote = undefined;
        }

    }, [formData.payments]);

    useEffect(() => {
        if (formData) {
            fetchTotals();
        }
    }, [formData.payments, exchangeTotal, couponTotal]);

    useEffect(() => {
        if (show) {
            fetchCoupon();
        }
    }, [formData.couponCode, formData.customerId]);

    function confirm() {
        const err = hasValidationError();
        if(!err) {
            const updatedPayments = formData.payments.map(payment => {
                return payment;
            });
            updateFormData({ payments: updatedPayments });
            onConfirm();
        }
    }

    function cancel() {
        const payments = formData.payments.filter((payment) => !payment.newRow);
        updateFormData({ payments: [...payments] });
        onCancel();
    }

    async function fetchTotals() {
        let total = 0;
        for (const payment of formData.payments) {
            const value = extractCurrencyNumber(payment.total);
            total += roundToTwo(value);
        }
        const roundedTotal = roundToTwo(total);
        const sanitizedTotal = sanitizeNegativeZero(roundedTotal);
        setPurchaseTotal(roundedTotal);
        const balance = roundToTwo(amount - exchangeTotal - sanitizedTotal - couponTotal);
        setBalanceToPay(sanitizeNegativeZero(balance));
    }


    // I18N
    const totals = [{
        name: 'Subtotal',
        value: amount,
    },
    {
        name: 'Valor devolução',
        value: exchangeTotal,
    },
    {
        name: 'Valor descontos',
        value: couponTotal,
    },
    {
        name: 'Total',
        value: purchaseTotal,
        className: 'balance-to-pay',
    }, {
        name: 'Saldo a pagar',
        value: balanceToPay,
    }];

    return (
        <div className={'sales-payment-modal'}>
            <SimpleConfirmModal
                // I18N
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                confirmText={'Concluir'}
                disabled={disabled}
            >
                <div className={'gd'}>
                    <div className={'gd-col-12  title'}>
                        {'Finalizar Venda'}
                    </div>
                </div>
                <div className={'row container-button-new-field'}>
                    <div className={'col-4'}>
                        <FieldCpf
                            // I18N
                            label={'CPF na nota fiscal'}
                            className={'field-cpf'}
                            onDisabled={formData.printedNote === false}
                            placeholder={'CPF para emissão de nota fiscal'}
                            value={salesForm?.cpfNote}
                            onChange={({ target }) => {
                                setSalesForm((saleForm) => ({
                                    ...saleForm,
                                    cpfNote: target.value
                                }));
                                setFormData((saleForm) => ({
                                    ...saleForm,
                                    cpfNote: target.value
                                }));
                            }}
                            validationError={formError.cpfNote}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Cupom de desconto'}
                            placeholder={formData.customerId ? 'Adicione o código do cupom' : 'Vincule um cadastro primeiro'}
                            value={couponCode}
                            disabled={!formData.customerId}
                            onChangeDebounce={() => {
                                setFormData((state) => ({ ...state, couponCode: couponCode }));
                            }}
                            onChange={({ target }) => {
                                setCouponCode(target.value.toUpperCase());
                            }}
                            validationError={formError.coupon}
                        />
                    </div>
                </div>

                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <PaymentList
                            currencyRefs={currencyRefs}
                            removeRowPayment={removeRowPayment}
                            setPurchaseTotal={setPurchaseTotal}
                            formData={formData}
                            updateFormData={updateFormData}
                        />
                    </div>
                </div>
                <div className={'container-button-new-field  '}>
                    <div className={'gd add-new-field'}>
                        <Button
                            // I18N
                            color={ButtonColor.BUTTON_COLOR_WHITE}
                            className={'button-new-field'}
                            onClick={addRowPayment}>
                            {'+ (F2) Adicionar novo campo'}
                        </Button>

                    </div>
                </div>

                <div className={'gd totals-gd'}>
                    <div className={'gd-col-12 totals-container'}>
                        {
                            totals.map(({ name, value, className }, i) => {
                                return (
                                    <div key={i}
                                        className={`total-payment-list ${className === 'balance-to-pay' ? (value >= 0 ? 'green' : 'red') : ''}`}>
                                        <div className={'gd gd-col--between col-styled'}>
                                            <div className={'gd-col-3'}>
                                                <span>
                                                    {name}
                                                </span>
                                            </div>
                                            <div className={'gd-col-9 justify-content-end'}>
                                                <span className={'value'}>
                                                    {value?.toLocaleString(undefined, {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </SimpleConfirmModal>

        </div>
    );
}

export default SalesPaymentModal;
