const blur = (cep) => {
    return '********';
};

const anonymize = () => {
    return '99999999';
};

const cep = {
    blur,
    anonymize,
};

export default cep;
