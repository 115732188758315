import ScreenHeader from '../components/logged/ScreenHeader';
import { unmaskPhone } from '../components/FieldPhone';
import Button, { ButtonColor, ButtonFontColor } from '../components/Button';
import { ValidationErrorUserText, } from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import * as CollaboratorsService from '../services/CollaboratorsService';
import { InfoModalStyle } from '../components/modal/InfoModal';
import { Loading, LoadingSize } from '../components/Loading';
import RouteChangePrompt from '../components/RouteChangePrompt';
import { parseNumberLocale } from '../util/numberParser';
import { PersonalDataForm, personalFormDataHasValidationError } from '../admin/collaborators/CollaboratorForm';
import { unmaskZipCode } from '../components/FieldZipCode';
import { unmaskCpf } from '../components/FieldCpf';

export default function MyProfile() {
    const emptyFormData = {
        picture: null,
        name: '',
        cpf: '',
        birthday: '',
        email: '',
        phone: '',
        cellphone: '',
        sellerId: '',
    };
    const emptyFormError = {
        picture: null,
        name: null,
        cpf: null,
        birthday: null,
        address: null,
        email: null,
        phone: null,
        cellphone: null,
    };
    const emptyAddressData = {
        zipCode: '',
        state: null,
        city: null,
        address: '',
        neighborhood: '',
        number: '',
        complement: '',
    };
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [addressData, setAddressData] = useState(emptyAddressData);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [pictureChanged, setPictureChanged] = useState(false);
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [loadingCollaborator, setLoadingCollaborator] = useState(true);
    const [collaboratorName, setCollaboratorName] = useState('');
    const [hasChange, setHasChange] = useState(false);

    // I18N
    const title = 'Meu perfil';

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((oldData) => {
            return {
                ...oldData,
                ...data,
            };
        });
        setHasChange(true);
    }

    function updateFormError(data) {
        // noinspection JSCheckFunctionSignatures
        setFormError((oldData) => {
            return {
                ...oldData,
                ...data,
            };
        });
    }

    async function fetchCollaborator() {
        try {
            setLoadingCollaborator(true);
            const collaborator = await CollaboratorsService.getMyProfile();
            setCollaboratorName(collaborator.name);
            setFormData({
                ...collaborator,
                birthday: collaborator.birthday?.split('T')[0],
                sellerId: collaborator.id,
            });
            setPictureChanged(false);
            setAddressData({
                zipCode: collaborator.zipCode ?? '',
                state: !collaborator.state ? null : { id: collaborator.state, value: collaborator.state },
                city: !collaborator.city ? null : { id: collaborator.city, value: collaborator.city },
                address: collaborator.address ?? '',
                neighborhood: collaborator.neighborhood ?? '',
                number: collaborator.number?.toLocaleString() ?? '',
                complement: collaborator.complement ?? '',
            });
        } catch (e) {
            // I18N
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Colaborador não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                });
            } else {
                backendConnectionError('Fail to fetch collaborator', e, null, title);
            }
        } finally {
            setLoadingCollaborator(false);
        }
    }

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchCollaborator();
    }, []);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (!loadingCollaborator) setHasChange(false);
    }, [loadingCollaborator]);

    function hasValidationError() {
        setValidateOnChange(true);
        setFormError(emptyFormError);
        return personalFormDataHasValidationError(formData, updateFormError, false);
    }

    useEffect(() => {
        const f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () =>  {
            removeHotkey(f6Hotkey);
        };
    }, []);

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                zipCode: unmaskZipCode(addressData.zipCode),
                state: addressData.state?.value ?? '',
                city: addressData.city?.value ?? '',
                address: addressData.address,
                neighborhood: addressData.neighborhood,
                number: addressData.number ? parseNumberLocale(addressData.number) : null,
                complement: addressData.complement,
                birthday: new Date(formData.birthday),
                cpf: unmaskCpf(formData.cpf),
                phone: unmaskPhone(formData.phone),
                cellphone: unmaskPhone(formData.cellphone),
                picture: pictureChanged ? formData.picture : null,
            };

            await CollaboratorsService.editMe(body);
            // I18N
            const message = 'Perfil atualizado com sucesso!';

            const style = InfoModalStyle.SUCCESS;
            const onClose = () => {
                fetchCollaborator();
            };

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to invite user', e, null, title, ValidationErrorUserText);
        } finally {
            setSaveLoading(false);
        }
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    const renderForm = () => {
        if (loadingCollaborator) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <PersonalDataForm
                        formData={formData}
                        user={user}
                        formError={formError}
                        updateFormData={updateFormData}
                        addressData={addressData}
                        setAddressData={setAddressData}
                        useStatusField={false}
                        onPictureChange={() => setPictureChanged(true)}
                    />
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-10'}/>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                ref={buttonSaveRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                loading={saveLoading}
                                onClick={save}
                            >
                                {'Salvar [F6]'}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <RouteChangePrompt enabled={hasChange}/>
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        // I18N
                        { name: loadingCollaborator ? '...' : collaboratorName },
                    ]}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getMyProfileRoute() {
    // I18N
    return '/meu-perfil';
}
